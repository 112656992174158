import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Layout from '../views/layout/layout.vue'
import Login from '../views/login/login.vue'
import Index from '../views/index/index.vue'
import BusinessIndex from '../views/business/index.vue'
import BusinessOrder from '../views/business/order-list.vue'
import demandDetail from '../views/business/demandDetail.vue'
import BusinessPlatform from '../views/business/platform-list.vue'
import plateDetail from "../views/business/plateDetail.vue";

import OrderIndex from '../views/order/index.vue'
import OrderWallet from '../views/order/wallet.vue'
import OrderTrade from '../views/order/trade-list.vue'
import OrderContract from '../views/order/contract-list.vue'
import OrderRecord from '../views/order/record-list.vue'

import StoreCaseIndex from '../views/store/case/index.vue'
import StoreCaseList from '../views/store/case/case-list.vue'
import StoreCaseAdd from '../views/store/case/case-add.vue'

import StoreResultIndex from '../views/store/result/index.vue'
import StoreResultList from '../views/store/result/result-list.vue'
import StoreResultAdd from '../views/store/result/result-add.vue'
import StoreProduct from '../views/store/product/product-list.vue'

import CompanyBasic from '../views/company/basic.vue'
import CompanyAccount from '../views/company/account.vue'
import FirmIndex from  '../views/store/Firm/index.vue'

const routes = [
    {
        path: '/',
        name: '',
        redirect: 'index',
        // component: Login
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '*',
        component: () => import('../views/error/404.vue')
    },
    {
        path: '/index',
        name: 'index',
        redirect: 'index/index',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: 'index',
                name: 'index-list',
                component: Index,
            },
            {
                path: 'list',
                name: 'index-list-all',
            }
        ]
    },
    {
        path: '/store',
        name: 'store',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: 'case',
                name: 'store-case',
                component: StoreCaseIndex,
                children: [
                    {
                        path: 'list',
                        name: 'case-list',
                        component: StoreCaseList,
                    },
                    {
                        path: 'add',
                        name: 'case-add',
                        component: StoreCaseAdd
                    }
                ]
            },
            {
                path: 'result',
                name: 'store-result',
                component: StoreResultIndex,
                children: [
                    {
                        path: 'list',
                        name: 'result-list',
                        component: StoreResultList,
                    },
                    {
                        path: 'add',
                        name: 'result-add',
                        component: StoreResultAdd
                    }
                ]
            },
            {
                path: 'product',
                name: 'store-product',
                component: StoreProduct
            },
            {
              path: 'firm/index',
              name: 'store-firm-index',
              component: FirmIndex
            },
        ]
    },
    {
        path: '/business',
        name: 'business',
        component: Layout,
        children: [
            {
                path: 'order',
                name: 'business-order',
                component: BusinessOrder
            },{
                path: 'demandDetail',
                name: 'demandDetail',
                component: demandDetail
            },
            {
                path: 'platform',
                name: 'business-platform',
                component: BusinessPlatform
            },
            {
              path: 'plateDetail',
              name: 'plateDetail',
              component: plateDetail
            }
        ]
    },
    {
        path: '/order',
        name: 'order',
        component: Layout,
        children: [
            {
                path: 'wallet',
                name: 'order-wallet',
                component: OrderWallet
            },
            {
                path: 'trade',
                name: 'order-trade',
                component: OrderTrade
            },
            {
                path: 'contract',
                name: 'order-contract',
                component: OrderContract
            },
            {
                path: 'record',
                name: 'order-record',
                component: OrderRecord
            },
        ]
    },
    {
        path: '/company',
        name: 'company',
        component: Layout,
        meta: {
            // requiresAuth: true
        },
        children: [
            {
                path: 'basic',
                name: 'basic',
                component: CompanyBasic,
            },
            {
                path: 'account',
                name: 'account',
                component: CompanyAccount,
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     let acsToken = sessionStorage.getItem('acsToken')
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         //这里判断用户是否登录，验证本地存储是否有token
//         if (!acsToken) { // 判断当前的token是否存在
//             next({
//                 path: '/login',
//                 // query: { redirect: to.fullPath }
//             })
//         } else {
//             next()
//         }
//     } else {
//         next() // 确保一定要调用 next()
//     }
// })

export default router
