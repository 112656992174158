<template>
  <div>
    <div class="flex-between">
      <div class="info-box">
        <div class="flex-center-s" style="position: relative">
          <img   :src="userAvatar?userAvatar: '../../assets/shop.png'"   style="border-radius: 50%;width: 60px;height: 60px">
          <img src="../../assets/setting.png" style="width: 16px;height: 16px;position: absolute;left: 0px;top: 0px">
          <div style="margin-left: 20px;">
            <div>
              <span style="font-size: 24px;color: #666666">{{userName}}，欢迎您</span>
              <img v-if="myInfo.isAuthentication == 2" src="../../assets/qyrz.png" style="width: 80px;height: 20px;margin-left: 12px">
              <img v-else src="../../assets/dqyrz.png" style="width: 80px;height: 20px;margin-left: 12px">
            </div>
            <div style="padding-top: 7px;color: #575C6F;font-size: 14px">{{myInfo.name}}</div>
          </div>
        </div>
        <div style="margin-top: 57px">
          <span style="color: #999999;font-size: 14px;padding-left: 22px">钱包</span>
          <span style="padding-left: 13px;color: #E94B22;font-size: 14px">0元</span>
          <span style="color: #999999;font-size: 14px;padding-left: 48px">积分</span>
          <span style="padding-left: 13px;color: #E94B22;font-size: 14px">0分</span>
        </div>
      </div>
      <div class="consultation-box">
        <div class="flex-between" style="border-bottom: 1px solid #EEEEEE;padding-bottom: 12px">
          <div class="flex-center-s">
            <div class="gradual-line"></div>
            <div style="font-size: 16px;font-weight: 500;padding-left: 5px">专家咨询</div>
          </div>
          <div class="flex-center-s tabs-box">
            <div @click="handleTab(i)" v-for="(item,i) in tabsList" :key="i" :style="currentTabIndex==i?'color:#E94B22':''">{{item}}</div>
          </div>
        </div>
        <div class="flex-center-s" style="align-items: flex-start;margin-top: 20px">
          <img src="../../assets/shop.png" style="width: 97px;height: 125px">
          <div style="margin-left: 20px; height: 125px; display: flex; flex-direction: column; space-between;">
            <div class="flex-center-s">
              <span style="font-size: 16px;font-weight: 500">专业咨询顾问·牛大拿</span>
              <span class="free-btn-box">免费咨询</span>
            </div>
            <div style="font-size: 14px;color: #575C6F;margin: 7px 0;">装修前的规划，是空间效果满意的关键，狸办公专业顾帮您把需求想法更好的落地</div>
            <div class="btns-box">
              <div>专业咨询</div>
              <div>项目委托</div>
              <div>询价比稿</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="demand-box">
      <div class="flex-between" style="border-bottom: 1px solid #EEEEEE;padding-bottom: 10px">
        <div class="flex-center-s">
          <div class="gradual-line"></div>
          <div style="font-size: 16px;font-weight: 500;padding-left: 5px">我的需求</div>
        </div>
        <router-link :to="'/business/platForm'" v-if="form"><p class="many">更多</p></router-link>
      </div>
      <div v-for="(fitem,index) in form" :key="index"
           @click.stop="check(index)"
           :style="index==0?'border-bottom: 1px solid #EEEEEE;padding-bottom: 24px':''">
        <div class="demand-title">
          <div>{{fitem.projectName}}({{fitem.businessCode}})</div>
          <div>应标商家：5  </div>
        </div>
        <div class="step-box">
          <div class="demand-step">
            <div v-for="(item,i) in (fitem.projectNature == 'sjxm' ? designStep:buildStep)" :key="i">
              <img src="../../assets/step-on.png" v-if="isStepCheck(i,index)">
              <div class="stem-icon-div" v-else></div>
              <span style="height: 8px" v-if="!isStepCheck(i,index)"></span>
              <span :style="isStepCheck(i,index)?'color:#575C6F':'color:#A8ACC1'">{{ item.text }}</span>
            </div>
          </div>
          <img @click="check(index)" src="../../assets/demand-more.png" alt="">
        </div>
      </div>
    </div>

    <div class="flex-center-s" style="margin-top: 10px;align-items: flex-start;height: 337px;">
      <div class="download-box">
        <div class="flex-between" style="border-bottom: 1px solid #EEEEEE;padding-bottom: 10px">
          <div class="flex-center-s">
            <div class="gradual-line"></div>
            <div style="font-size: 16px;font-weight: 500;padding-left: 5px">我的文件</div>
          </div>
          <div class="flex-center-s">
            <el-select v-model="optionValue" size="small" style="width: 96px">
              <el-option
                label="地址"
                value="0">
              </el-option>
            </el-select>
            <el-select v-model="optionValue" size="small" style="width: 100px;margin-left: 8px">
              <el-option
                label="按下载量"
                value="0">
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <div class="flex-between" v-for="item in fileList " :key="item.id" style="margin-bottom: 13px">
            <div class="flex-center-s">
              <img src="../../assets/shop.png" style="width: 20px;height: 20px">
              <span style="padding-left: 10px;font-weight: 500">{{item.name}}</span>
            </div>
            <div style="font-size: 14px">
              <span style="color: #999999;padding-right: 62px">{{item.fileSize}}K</span>
              <span style="color: #999999;padding-right: 52px">{{ item.downloadNum }}次</span>

              <a :href="host + item.fileUrl" style="color: #E94B22" :download="item.name">下载</a>
            </div>
          </div>
        </div>
      </div>

      <div class="coupon-box">
        <div class="flex-between" style="border-bottom: 1px solid #EEEEEE;padding-bottom: 10px">
          <div class="flex-center-s">
            <div class="gradual-line"></div>
            <div style="font-size: 16px;font-weight: 500;padding-left: 5px">优惠券（6）</div>
          </div>
          <router-link :to="'/business/platForm'" v-if="form"><p class="many">更多</p></router-link>
        </div>

        <div class="coupon-item" style="color: #E94B22;margin-top: 16px">
          <div>
            <div>
              <span style="font-size: 18px">￥</span>
              <span style="font-size: 22px">3000</span>
            </div>
            <span>立即使用</span>
          </div>
          <div>仅限搬家使用</div>
          <div>期限至：2022.12.31 23:59:59</div>
        </div>
        <div class="coupon-item" style="color: #E94B22;margin-top: 8px">
          <div>
            <div>
              <span style="font-size: 18px">￥</span>
              <span style="font-size: 22px">3000</span>
            </div>
            <span>立即使用</span>
          </div>
          <div>仅限搬家使用</div>
          <div>期限至：2022.12.31 23:59:59</div>
        </div>
        <div class="coupon-item" style="color: #0085FF;background-color:#E9F7FF;margin-top: 8px">
          <div>
            <div>
              <span style="font-size: 16px;margin-bottom: 10px;display: inline-block">环境免费检测</span>
            </div>
            <span style="background-color: #0085FF">立即使用</span>
          </div>
          <div>仅限环境检测使用</div>
          <div>期限至：2022.12.31 23:59:59</div>
        </div>
      </div>

      <div class="collect-box">
        <div class="flex-between" style="border-bottom: 1px solid #EEEEEE;padding-bottom: 12px">
          <div class="flex-center-s">
            <div class="gradual-line"></div>
            <div style="font-size: 16px;font-weight: 500;padding-left: 5px">我的收藏</div>
          </div>
          <div class="flex-center-s tabs-box">
            <div @click="handleCollect(0)" :style="currentCollectIndex==0?'color:#E94B22':''">案例</div>
            <div @click="handleCollect(1)" :style="currentCollectIndex==1?'color:#E94B22':''">服务商</div>
            <!-- <div @click="handleCollect(2)" :style="currentCollectIndex==2?'color:#E94B22':''">足迹</div> -->
          </div>
        </div>
        <div class="collect-img-box" v-if="currentCollectIndex == 0">
          <div v-for="item in collectList" :key="item" class="collect-img"  @click="toCasePage(item.caseId)">
            <img :src="host +item.caseCoverFileUrl+'/merchantlistopus'" style="height: 100%;width: 100%">
          </div>
        </div>
        <div class="collect-img-box" v-if="currentCollectIndex == 1">
          <div v-for="item in followList" :key="item" class="collect-img-1" style="text-align: center" @click="toStorePage(item.storeId)">
            <img :src="host +item.storeLogoUrl" style="height: 50px;width: 50px;border-radius: 50%">
            <div style="color: #575C6F;font-size: 14px">{{item.storeName}}</div>
            <div style="background-color: #FAF4E1;display: flex;align-items: center;margin-top: 7px;border-radius: 12px;justify-content: center">
              <img src="../../assets/star.png" style="width: 12px;height: 12px">
              <p style="color: #FAAD15">{{item.storeSortPraise}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {getUserName,getUserAvatar} from "../../utils/auth.js";
export default {
  name: '',
  data() {
    return {
      host: 'https://libangongoss.oss-cn-shanghai.aliyuncs.com/prod',
      //设计商机进展  EPC商机进展
      designStep: [
        {
          key:'xqfb',
          text:'需求发布'
        },{
          key:'ddkfqr',
          text:'待客服确认'
        },{
          key:'khjlsmfw',
          text:'客户经理上门服务'
        },{
          key:'fwsybStatus',
          text:'匹配服务商'
        },{
          key:'xqdyStatus',
          text:'需求调研'
        },{
          key:'xckcStatus',
          text:'现场勘察'
        },{
          key:'fahbStatus',
          text:'方案汇报'
        },{
          key:'jgbxStatus',
          text:'价格比选'
        },{
          key:'htqyStatus',
          text:'合同签约'
        },{
          key:'zbjgStatus',
          text:'中标结果通知'
        },{
          key:'orderCloseStatus',
          text:'关闭/完成'
        },
        // {
        //   key:'sdStatus',
        //   text:'输单' //3
        // },{
        //   key:'tdStatus',
        //   text:'无效/退单' //2
        // },
      ],
      //施工商机进展
      buildStep: [
        {
          key:'xqfb',
          text:'需求发布'
        },{
          key:'ddkfqr',
          text:'待客服确认'
        },{
          key:'khjlsmfw',
          text:'客户经理上门服务'
        },{
          key:'fwsybStatus',
          text:'匹配服务商'
        },{
          key:'xckcStatus',
          text:'现场勘察'
        },{
          key:'fahbStatus',
          text:'招标答疑'
        },{
          key:'jgbxStatus',
          text:'价格比选'
        },{
          key:'htqyStatus',
          text:'合同签约'
        },{
          key:'zbjgStatus',
          text:'中标结果通知'
        },{
          key:'orderCloseStatus',
          text:'关闭/完成'
        },
        // {
        //   key:'sdStatus',
        //   text:'输单' //3
        // },{
        //   key:'tdStatus',
        //   text:'无效/退单' //2
        // },
      ],
      form: [],
      menuI:0,
      headlinesI:0,
      collectionI:0,
      myInfo:{},
      //专家咨询tab
      tabsList : ['项目管理','选址','设计','装修','配套工程','家具','保洁','搬家','入驻服务'],
      //当前选中的tab
      currentTabIndex:0,
      optionValue:'0',
      //我的收藏
      currentCollectIndex:0,
      fileList:[],
      collectList:[],
      followList:[],
    }
  },
  computed:{
    userName(){
      return getUserName()
    },
    userAvatar(){
      return getUserAvatar()
    }
  },//computed
  created() {
    this.queryList();
    this.getMyInfo();
    this.getFileList();
    this.getCollectList();//收藏-案例列表
    this.getFollowList();//关注-店铺列表
  },
  methods: {
    toStorePage(storeId) {
        window.open("https://libangong.com/#/layout/store/case?storeId="+storeId, '_blank');
    },
    toCasePage(caseId) {
        window.open("https://libangong.com/#/layout/content?caseId="+caseId, '_blank');
    },

    getCollectList(){
      this.$api.get('/customerApi/collectFollow/collect/list').then(res=>{
        this.collectList = res.rows
      })
    },
    getFollowList(){
      this.$api.get('/customerApi/collectFollow/follow/list').then(res=>{
        this.followList = res.rows
      })
    },

    getFileList(){
      this.$api.get('/customerApi/customerResourceFile/list').then(res=>{
        this.fileList = res.rows
      })
    },
    /**
     * 专家咨询点击tab回调
     */
    handleTab(i){
      this.currentTabIndex = i
    },
    /**
     * 我的收藏点击tab回调
     */
    handleCollect(i){
      this.currentCollectIndex = i
    },
    /**
     * 获取个人信息
     */
    getMyInfo(){
      this.$api.get('/customerApi/customer/getCustomerFirm').then(res=>{
        this.myInfo = res.data
      })
    },
    /**
     * 此步骤是否选择
     */
    isStepCheck(index,formIndex) {

      if(this.form[formIndex].projectNature == 'sjxm'){
        //输单状态为3 无效为2
        if((this.designStep[index].key!='orderCloseStatus'&&this.form[formIndex][this.designStep[index].key] == 0 ) || (this.designStep[index].key=='orderCloseStatus'&&this.form[formIndex][this.designStep[index].key] == 1) ){
          return false
        }else{
          return true
        }
      } else{
        //输单状态为3 无效为2
        if((this.buildStep[index].key!='orderCloseStatus'&&this.form[formIndex][this.buildStep[index].key] == 0)|| (this.buildStep[index].key=='orderCloseStatus'&&this.form[formIndex][this.buildStep[index].key] == 1) ){
          return false
        }else{
          return true
        }
      }
    },
    /**
     * 跳转到账户管理页面
     */
    toAccount(){
      this.$router.push('/store/result/list')
    },
    /**
     * 立即咨询
     */
    winOpenConsult(){
      window.open(process.env.VUE_APP_CUSTOMER_URL + 'layout/guide', '_blank');
    },
    queryList(){
      this.$api.get('/customerApi/businessOpportunity/list?pageSize=2&pageNum=1')
        .then(res => {
          if(res.code == '200') {
            this.form=[...res.rows]
          }
        })
    },
    check(num){
      this.$router.push({
        path:'/business/demandDetail',
        query: {projectName:this.form[num].projectName,
          ownerName:this.form[num].ownerName,
          customerId:this.form[num].customerId,
          customerFirmId:this.form[num].customerFirmId,
          id:this.form[num].id
        },
      })
    },
    /**
     * 退出登录
     */
    logout(){
      alert("退出登录--1");
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        alert("退出登录--2");
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/login';
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
*{
  font-family: PingFangSC-Medium, PingFang SC;
}
.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-s{
  display: flex;
  align-items: center;
}
.info-box{
  // width: 506px;
  width: 43%;
  height: 215px;
  margin-right: 10px;
  background: linear-gradient(144deg, #FFF7F1 0%, #FFFFFF 100%);
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  padding: 25px 20px;
}
.consultation-box{
  flex: 1;
  // width: calc(100% - 516px);
  height: 215px;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  padding: 13px 20px;
}
.gradual-line{
  width: 3px;
  height: 13px;
  background: linear-gradient(360deg, #FAC400 0%, #FF3700 100%);
}
.tabs-box>div{
  margin-left: 16px;
  font-size: 14px;
  color: #575C6F;
  &:hover{
    cursor: pointer;
  }
}
.free-btn-box{
  background: #FFEBE5;
  border-radius: 1px;
  width: 64px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #E94B22;
  margin-left:8px;
  display: inline-block;
}
.btns-box{
  display: flex;
  align-items: center;
  margin-top: 15px;
  &>div:nth-child(1){
    background-color: #E94B22;
    color: #FFFFFF;
  }
  &>div:nth-child(2){
    font-weight: 500;
    border: 1px solid #CCCCCC;
  }
  &>div:nth-child(3){
    font-weight: 500;
    border: 1px solid #CCCCCC;
  }
}
.btns-box>div{
  width: 112px;
  height: 35px;
  border-radius: 3px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  margin-right: 8px;
  &:hover{
    cursor: pointer;
  }
}
.demand-box{
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  margin-top: 10px;
}
.many{
  font-size: 14px;
  color: #9E9E9E;
  &:hover{
    cursor: pointer;
  }
}
.demand-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  &>div:nth-child(1){
    font-size: 16px;
    font-weight: 500;
  }
  &>div:nth-child(2){
    width: 100px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #E94B22;
    line-height: 35px;
    text-align: center;
    color: #E94B22;
    font-size: 14px;
  }
}
.step-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbfbfb;
  border-radius: 3px;
  padding: 15px 20px 10px 10px;
  margin-top: 8px;
}
.demand-step {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    width: 100%;
    border-top: 1px solid #CBCFE4;
    position: absolute;
    left: 0;
    top: 5px;
    right: 0;
  }

  & > div:first-child {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      left: 0;
      top: 0;
      background-color: #fbfbfb;
      z-index: 3;
    }
  }

  & > div:last-child {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      right: 0;
      top: 0;
      background-color: #fbfbfb;
      z-index: 3;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;

    & > div:nth-child(1) {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: #CBCFE4;
      z-index: 4;
      position: absolute;
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      z-index: 4;
    }

    & > span {
      padding-top: 10px;
      font-size: 14px;
    }
  }
}
.download-box{
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  width: calc(100% - 320px - 320px - 20px);
  height: 100%;
  overflow-y: auto;
}
.coupon-box{
  width: 320px;
  height: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  padding: 20px 20px;
  margin-left: 10px;
}
.coupon-item{
  margin-left: 10px;
  margin-right: 50px;
  background-color: #FFEBE5;
  border-radius: 10px;
  padding: 6px 12px;
  height: 80px;
  position: relative;
  &:before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    left: -4px;
    bottom: 17px;
    border-radius: 50%;
  }
  &:after{
    right: -3px;
    bottom: 17px;
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  &>div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: space-between;
    &>span:nth-child(2){
      display: inline-block;
      width: 60px;
      height: 22px;
      background: #E94B22;
      border-radius: 11px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
      font-size: 12px;
    }
  }
  &>div:nth-child(2){
    font-size: 12px;
  }
  &>div:nth-child(3){
    font-size: 12px;
    border-top: 1px dashed #9e9c9c;
    color: #999999;
  }
}
.collect-box{
  padding: 17px;
  width: 320px;
  height: 100%;
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #EBEEF5;
  margin-left: 10px;
}
.collect-img-box{
  height: 260px;
  overflow-y: auto;
  width: 100%;
  margin-top: 8px;
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  &::-webkit-scrollbar{
    //display: none;
  }
}
/*滚动条整体样式,高宽分别对应横竖滚动条的尺寸*/
.collect-img-box::-webkit-scrollbar {
  width: 4px;
  height: 2px;
  scrollbar-arrow-color:#898989;
}
/*滚动条里面小方块*/
.collect-img-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(#D8D8D8,0.2);
  scrollbar-arrow-color:#898989;
}
/*滚动条里面轨道*/
.collect-img-box::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  //border-radius: 0;
  //background: rgba(0,0,0,0.1);
}
.collect-img{
  width: calc(100% / 3);
  height: 120px;
  padding-right: 10px;
  margin-top: 8px;
  padding-right: 10px;
  position: relative;
  &:hover{
    cursor: pointer;
    &:after{
      position: absolute;
      left: 0px;
      content: "去看看";
      background: #000000;
      opacity: 0.6;
      width: calc(100% - 10px);
      height: 120px;
      text-align: center;
      line-height: 120px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.collect-img-1{
  width: calc(100% / 3);
  height: 120px;
  padding-right: 10px;
  margin-top: 8px;
  padding-right: 10px;
}
</style>
