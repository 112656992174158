<template>
  <div class="container">
    <div class="page-title">
      <span class="title">个人资料</span>
    </div>
    <p class="data-dateil" style="display: flex;align-items: center"><span>头像</span><img style="width: 50px;height: 50px;" v-if="form.avatar!=''" :src="form.avatar" alt=""><span v-else>暂无图片</span></p>
    <p class="data-dateil"><span>用户名</span>{{form.loginName}}</p>
    <!--<p class="data-dateil"><span>昵称</span>{{form.loginName}}</p>-->
    <p class="data-dateil"><span>姓名</span>{{form.name}}</p>
    <p class="data-dateil"><span>性别</span>{{form.sex==0?'男':(form.sex==1?'女':'未知')}}</p>
    <p class="data-dateil"><span>手机号</span>{{form.phonenumber}}</p>
    <!--<p class="data-dateil"><span>生日</span>{{form.loginName}}</p>-->
    <el-button type="primary" @click="dialogFormVisible = true">修改</el-button>
    <el-dialog title="修改个人资料" width="900px" :visible.sync="dialogFormVisible">
      <el-row>
        <el-form :model="form" :rules="formnRules" ref="signForm">
          <el-col :span="12">
            <el-form-item label="用户名" prop="loginName" :label-width="formLabelWidth">
              <el-input disabled v-model="form.loginName" placeholder="请输入用户名" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">-->
            <!--<el-form-item label="昵称" prop="ownerName" :label-width="formLabelWidth">-->
              <!--<el-input v-model="form.ownerName"  placeholder="请输入昵称" autocomplete="off"></el-input>-->
            <!--</el-form-item>-->
          <!--</el-col>-->
          <el-col :span="12">
            <el-form-item label="姓名" prop="name"  :label-width="formLabelWidth">
              <el-input v-model="form.name" placeholder="请输入姓名" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex"  :label-width="formLabelWidth">
              <el-select style="width: 100%;" v-model="form.sex" placeholder="请选择合同类型">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">-->
            <!--<el-form-item label="生日" prop="signTime" :label-width="formLabelWidth">-->
              <!--<el-date-picker-->
                <!--style="width: 100%;"-->
                <!--v-model="form.signTime"-->
                <!--type="date"-->
                <!--value-format="yyyy-MM-dd"-->
                <!--placeholder="选择生日">-->
              <!--</el-date-picker>-->
            <!--</el-form-item>-->
          <!--</el-col>-->
          <el-col :span="24">
            <el-form-item label="头像" prop="avatar" :label-width="formLabelWidth">
              <el-upload
                :action="uploadFileUrl"
                :headers="headers"
                accept=".jpeg, .png, .jpg"
                list-type="picture-card"
                :on-success="handleCoverUploadSuccess"
                :on-remove="handleCoverUploadRemove"
                :file-list="form.flieList"
                :on-exceed="exceed"
                :limit="1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('signForm')">提交</el-button>
        <el-button @click="resetForm('signForm')">重置</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getToken } from '@/utils/auth'
export default {
    name: 'case-list',
    components: {},
    data () {
      return {
        form:[],
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
          ],
        },
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        enterprise:[],
        dialogFormVisible: false,
        formLabelWidth: '120px',
        headers: {
          Authorization: "Bearer " + getToken()
        },
        formnRules:{
          loginName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
          flieList: [{ required: true, message: '请上传头像', trigger: 'change' }],
        },
      };
    },
    created() {

    },
    mounted() {
      this.queryList()
    },
    methods: {
      queryList(){
        this.$api.get('/customerApi/customer/myInfo')
          .then(res => {
            if(res.code == '200') {
              // this.form.loginName=res.data.loginName
              console.log(this.form)
              this.form=res.data
              this.form.flieList.push(res.data.avatar)
            }
          })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let form={
              loginName:this.form.loginName,
              name:this.form.name,
              sex:this.form.sex,
              avatar:'',
              id:this.form.id,
            }
            form.avatar=this.form.flieList.toString()
            delete form['flieList']
            this.$api.put('/customerApi/customer',form)
              .then(res => {
                if(res.code == '200') {
                  this.$message.success(res.msg);
                  this.dialogFormVisible=false
                  this.queryList()
                }
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      exceed(){
        this.$message.error(`只允许上传一个头像!`);
      },
      handleCoverUploadRemove(file, fileList) {
        this.form.flieList = [];
      },
      handleCoverUploadSuccess(res, file, fileList) {
        this.form.flieList = [];
        if(res.code == 200) {
          this.form.flieList.push(res.data.url);
          // this.form.coverFileId = res.data.fileId;
          this.$message.success("上传成功");
        } else {
          this.form.flieList = [];
          this.$message.error(res.msg);
        }
      },
    }
}

</script>
<style lang='less' scoped>
  .data-dateil{
    line-height: 40px;
    font-size: 14px;
    margin-bottom: 22px;
    span{
      display: inline-block;
      width: 70px;
      padding-right: 12px;
    }
  }
  .page-title{
    margin-bottom: 20px;
  }
</style>
