<template>
  <div>
    <div class="header">
      <div>
        <p class="title">{{ form.firmName }}</p>
        <p class="info">
          <span class="code">{{ form.clueCode}}</span>
          <span>{{ form.createTime}}</span>
        </p>
      </div>
      <a href="javascript: void(0)" class="back" @click="goBack">返回</a>
    </div>
    <div class="container">
      <div class="demand-detail-item">
        <div class="step-box">
          <div class="step-view">
            <div class="el-steps el-steps--horizontal">
              <div
                class="el-step is-horizontal is-center"
                style="flex-basis: 50%;margin-right: 0px;"
              >
                <template>
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;">
                      <i class="el-step__line-inner"></i>
                    </div>
                    <div
                      class="el-step__icon is-text step-active"
                    >
                      <i class="el-step__icon-inner is-status el-icon-check"></i>
                    </div>
                  </div>
                  <div class="el-step__main">
                    <div class="el-step__title is-process">
                      需求发布
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="el-step is-horizontal is-center"
                style="flex-basis: 50%;margin-right: 0px;"
              >
                <template>
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;">
                      <i class="el-step__line-inner"></i>
                    </div>
                    <div
                      v-if="form.clueStatus == 5"
                      class="el-step__icon is-text step-active"
                    >
                      <i class="el-step__icon-inner is-status el-icon-check"></i>
                    </div>
                    <div v-else class="el-step__icon is-text"></div>
                  </div>
                  <div class="el-step__main">
                    <div class="el-step__title is-process">
                      客服确认
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="el-step is-horizontal is-center"
                style="flex-basis: 50%;margin-right: 0px;"
              >
                <template>
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;">
                      <i class="el-step__line-inner"></i>
                    </div>
                    <div
                      v-if="form.clueStatus == 100"
                      class="el-step__icon is-text step-active"
                    >
                      <i class="el-step__icon-inner is-status el-icon-check"></i>
                    </div>
                    <div v-else class="el-step__icon is-text"></div>
                  </div>
                  <div class="el-step__main">
                    <div class="el-step__title is-process">
                      匹配服务商
                    </div>
                  </div>
                </template>
              </div>
              <div
                class="el-step is-horizontal is-center"
                style="flex-basis: 50%;margin-right: 0px;"
              >
                <template>
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;">
                      <i class="el-step__line-inner"></i>
                    </div>
                    <div
                      v-if="form.clueStatus == 100"
                      class="el-step__icon is-text step-active"
                    >
                      <i class="el-step__icon-inner is-status el-icon-check"></i>
                    </div>
                    <div v-else class="el-step__icon is-text"></div>
                  </div>
                  <div class="el-step__main">
                    <div class="el-step__title is-process">
                      客户经理上门服务
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="service-verify">
            <div class="verify-div" v-if="form.clueStatus != 5">等待客服确认</div>
            <div class="verify-div1" v-else>
              <div>确认结果: 无效需求</div>
              <div>原因: {{form.followInfo}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="需求概览" name="1">

            <el-descriptions title="基础信息" labelClassName="label-class">
              <el-descriptions-item label="公司名称">
                <span v-show="!editFlag">{{ form.customerFirm?form.customerFirm:'未填写' }}</span>
                <el-input v-show="editFlag" v-model="form.customerFirm" size="small"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="联系人">
                <span v-show="!editFlag">{{form.contactName ? form.contactName:'未填写'}}</span>
                <el-input v-show="editFlag" size="small" v-model="form.contactName"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="联系方式">
                <span v-show="!editFlag">{{ form.phone?form.phone:'未填写' }}</span>
                <el-input v-show="editFlag" v-model="form.phone" size="small"></el-input>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="所属行业">
                <my-dict-tag :options="dict.type.firm_trade" :value="form.firmTrade" v-show="!editFlag"></my-dict-tag>
                <el-select v-model="form.firmTrade" placeholder="请选择" size="small" v-show="editFlag">
                  <el-option
                    v-for="item in dict.type.firm_trade"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="公司类型">
                <my-dict-tag :options="dict.type.firm_type" :value="form.firmType" v-show="!editFlag"></my-dict-tag>
                <el-select v-model="form.firmType" placeholder="请选择" size="small" v-show="editFlag">
                  <el-option
                    v-for="item in dict.type.firm_type"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-descriptions-item> -->
            </el-descriptions> 

            <el-descriptions title="需求信息" labelClassName="label-class">
                <el-descriptions-item label="项目面积">
                  <span v-show="!editFlag">{{ form.area ?form.area:'未填写'}}</span>
                  <el-input v-show="editFlag" v-model="form.area" size="small">
                    <template slot="append">平方</template>
                  </el-input>
                </el-descriptions-item>
                <el-descriptions-item label="需求类型">
                  <my-dict-tag :options="dict.type.lbg_request_type" :value="form.requestType" v-show="!editFlag"></my-dict-tag>
                  <el-select v-model="form.requestType" placeholder="请选择" size="small" v-show="editFlag">
                    <el-option
                      v-for="item in dict.type.lbg_request_type"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="项目类型">
                  <my-dict-tag :options="dict.type.lbg_project_type" :value="form.projectType" v-show="!editFlag"></my-dict-tag>
                  <el-select v-model="form.projectType" placeholder="请选择" size="small" v-show="editFlag">
                    <el-option
                      v-for="item in dict.type.lbg_project_type"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="场地性质">
                    <my-dict-tag :options="dict.type.lbg_site_type" :value="form.siteType" v-show="!editFlag"></my-dict-tag>
                    <el-select v-model="form.siteType" placeholder="请选择" size="small" v-show="editFlag">
                      <el-option
                        v-for="item in dict.type.lbg_site_type"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="场地状态">
                  <my-dict-tag :options="dict.type.lbg_site_status" :value="form.siteStatus" v-show="!editFlag"></my-dict-tag>
                  <el-select v-model="form.siteStatus" placeholder="请选择" size="small" v-show="editFlag">
                    <el-option
                      v-for="item in dict.type.lbg_site_status"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-descriptions-item>
                <el-descriptions-item label="免租期">
                  <span v-show="!editFlag">{{ form.siteFreeTime?form.siteFreeTime:'0' }}天</span>
                  <el-input v-show="editFlag" v-model="form.siteFreeTime" size="small">
                    <template slot="append">天</template>
                  </el-input>
                </el-descriptions-item>
                <el-descriptions-item label="项目地址">
                  <span v-show="!editFlag">{{ form.addressAll?form.addressAll + '/'+form.address:'未填写' }}</span>
                  <div v-if="editFlag" style="display: flex;align-items: center">
                    <area-select @areaData="getAreaDataForm" ref="formAreaSelect"></area-select>
                    <el-input v-model="form.address" size="small" style="margin-left: 10px"></el-input>
                  </div>
                </el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="需求计划" labelClassName="label-class">
              <el-descriptions-item label="计划装修时间">
                <span v-show="!editFlag">{{ form.planTimeStatus?form.planTimeStatus:'未填写' }}</span>
                <el-date-picker
                  v-show="editFlag"
                  v-model="form.planTimeStatus"
                  type="date"
                  size="small"
                  placeholder="选择日期">
                </el-date-picker>
              </el-descriptions-item>
              <el-descriptions-item label="装修预算">
                <span v-show="!editFlag">{{ form.budget?form.budget:'未填写' }} </span>
                <el-input v-show="editFlag" v-model="form.budget" size="small">
                  <template slot="append">元</template>
                </el-input>
              </el-descriptions-item>
              <el-descriptions-item label="预计勘测时间">
                <span v-show="!editFlag">{{ form.surveyTime?form.surveyTime:'未填写' }}</span>
                <el-date-picker
                  v-show="editFlag"
                  v-model="form.surveyTime"
                  type="date"
                  size="small"
                  placeholder="选择日期">
                </el-date-picker>
              </el-descriptions-item>
            </el-descriptions>

            <div class="footer">
              <el-button size="small" v-show="(!editFlag) && form.clueStatus!=5" @click="handleEdit()">完善需求信息</el-button>
              <el-button type="primary" :disabled="saving" size="small" v-if="editFlag" @click="handleUpdate()">提交需求信息</el-button>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import myDictTag from "./components/myDictTag.vue";
import AreaSelect from '@/components/AreaSelect';
export default {
  dicts: ['lbg_request_type', 'lbg_project_type', 'lbg_site_status', 'lbg_site_type', 'business_platform_status', 'lbg_enterprise_service_type', 'firm_trade', 'firm_type', 'bidding_status','lost_reason','invalid_reason'],
  components:{myDictTag,AreaSelect},
  data() {
    return {
      form: {},
      editFlag:false,
      activeName: '1',
      saving: false
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    /**
     * 返回
     */
    goBack() {
      this.$router.go(-1)
    },
    getDetail() {
      this.$api.get(`/customerApi/clue/${this.$route.query.id}`).then(res => {
          if (res.code == '200') {
            this.form = res.data
          }
        })
    },
    handleEdit(){
      this.editFlag = true
      this.$nextTick(()=>{
        this.$refs.formAreaSelect.areaArray = [this.form.provinceId, this.form.cityId+1, this.form.districtId+2];
      })
    },
    handleUpdate(){
      if(this.saving) {
        return false;
      }
      this.saving = true;
      this.editFlag = false;
      this.$api.put('/customerApi/clue',{
        ...this.form
      }).then(res=>{
        if(res.code == 200){
          this.getDetail()
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
        this.saving = false;
      })
    },

    getAreaDataForm(provinceId, cityId, districtId){
      this.form.provinceId = provinceId;
      this.form.cityId = cityId;
      this.form.districtId = districtId;
    },
    handleClick() {

    }
  }
}
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 9px 10px 0 25px;
  width: 100%;
  height: 86px;
  background: linear-gradient(90deg, #FF6646 0%, #FF9139 100%);

  .title {
    margin: 6px 0 10px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
  }
  .info {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;

    .date {
      margin-right: 24px;
    }
  }
  .back {
    display: block;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #EFEFEF;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
  }
}
.step-box{
  padding: 10px 0;
  margin: 10px 0 70px;
  width: 100%;
  min-height: 67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background: rgba(216, 216, 216, .1);
}
.step-view {
  flex: 1;

  .el-step__icon {
    width: 10px;
    height: 10px;
    background: #CBCFE4;
    border: 0;
  }
  .el-step__line {
    height: 1px;
  }
  .step-active {
    width: 16px;
    height: 16px;
    background: #E84B22;
    border-color: #fff;
    color: #fff;

    i {
      font-size: 12px;
    }
  }
  .el-step__line-inner {
    transition-delay: 0ms;
    border-width: 0px;
    width: 0%;
  }
  .el-step__title {
    font-size: 13px;
    color: #575C6F;
    // display: flex;
    // align-items: center;
  }
}
.service-verify{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verify-div{
  text-align: center;
  padding: 20px;
  border: 1px solid #ada8a8;
  border-radius: 12px;
  margin-bottom: 10px;
  font-size: 14px;
  display: none;
}
.verify-div1{
  padding: 20px;
  border: 1px solid #ada8a8;
  border-radius: 12px;
  margin-bottom: 10px;
  font-size: 14px;
  &>div:nth-child(1){
    font-weight: bold;
  }
  &>div:nth-child(2){
    font-size: 12px;
    padding-top: 5px;
  }
}

.section {
  padding: 0 10px;
}
.footer {
  margin-top: 20px;
  text-align: center;
}
::v-deep .el-descriptions {
  margin-top: 34px;
  padding-bottom: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  border-bottom: 1px solid #eee;

  &:last-child {
    border: 0;
  }
  
  .label-class {
    display: block;
    width: 110px;
    text-align: right;
    color: #575C6F;
  }
  .el-select, .el-date-editor {
    width: 100%;
  }
  .el-cascader {
    min-width: 270px;
  }
}
.container {
  height: auto !important;
}

.goBack {
  color: rgb(54, 93, 255);

  &:hover {
    cursor: pointer;
  }
}

.binding {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #4b4d59;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9eef0;
  margin-bottom: 20px;
}

.demand-detail-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.demand-detail-item {
  margin-bottom: 10px;
}

.step-line {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;

  .step-line-inner {
    position: absolute;
    left: 50%;
    right: -50%;
    top: 10px;
    height: 2px;
    border-color: inherit;
    background-color: #c0c4cc;
  }

  .step-line-item {
    flex: 1;

    .step-line-top {
      text-align: center;
      position: relative;
      margin-bottom: 20px;
    }

    .step-line-title {
      text-align: center;
      font-size: 13px;
      color: #333;

      span {
        color: #409eff;
        text-align: center;
        font-size: 13px;
        display: block;
      }
    }

    .step-line-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      background-color: #cacfe4;
      z-index: 10;
      position: relative;
    }
  }

}

.service-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .service-item {
    width: calc(50% - 10px);
    overflow: hidden;
    // min-width: 400px;
    background-color: rgb(233, 238, 240);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .shop-left {
      /*display: flex;*/
      /*margin-right: 80px;*/
      width: 69%;

      .shop-pic {
        width: 100px;
        height: 100px;
        float: left;
        border-radius: 5px;
        overflow: hidden;
      }
    }

    .shop-right {
      width: 30%;

      .shop-status {
        height: 20px;
        position: relative;
        display: inline-block;
        padding-right: 20px;
        margin-right: 5px;
        margin-bottom: 5px;

        p {
          font-size: 12px;
          line-height: 20px;
        }

        img {
          position: absolute;
          right: 0;
          top: 2px;
          width: 14px;
        }

        div {
          width: 14px;
          height: 14px;
          background: #CCCCCC;
          border-radius: 50%;
          top: 2px;
          position: absolute;
          right: 0;
        }
      }
    }

    .shop-brief {
      margin-left: 6px;
      float: left;
      width: 68%;
    }

    .shop-brief-bottom > p {
      font-size: 16px;
      color: #8C90A2;
      line-height: 25px;
    }
  }
}

.demand-step {
  // width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    width: 100%;
    border-top: 1px solid #cbcfe4;
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
  }

  & > div:first-child {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      left: 0;
      top: 0;
      background-color: #ffffff;
      z-index: 3;
    }
  }

  & > div:last-child {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      right: 0;
      top: 0;
      background-color: #ffffff;
      z-index: 3;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    // height: 80px;

    & > div:nth-child(1) {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #cbcfe4;
      z-index: 4;
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      z-index: 4;
    }

    & > span {
      font-size: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #575C6F;
    }
  }
}
/deep/.el-tabs__nav{
  width: 100%;
}
/deep/#tab-xqgl,/deep/#tab-jdxq{
  width: 50%;
  text-align: center;
}
/deep/.el-descriptions-item__container{
  align-items: center;
}
</style>
