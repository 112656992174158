const industryList = [
    {
        label: 'IT/互联网/科技',
        value: '1'
    },
    {
        label: '文化/广告/传媒',
        value: '2'
    },
    {
        label: '金融/银行/投资',
        value: '3'
    },
    {
        label: '咨询/顾问/服务',
        value: '4'
    },
    {
        label: '医疗/教育',
        value: '5'
    },
    {
        label: '制造/实业/电子',
        value: '6'
    },
    {
        label: '其他',
        value: '6'
    }
];
const areaList = [
    {
        label: '500㎡ 以下',
        value: '1'
    },
    {
        label: '500㎡ - 1000㎡',
        value: '2'
    },
    {
        label: '1000㎡ - 2000㎡',
        value: '3'
    },
    {
        label: '2000㎡ 以上',
        value: '4'
    }
];
const statusList = [
    {
        label: '待提交',
        value: '1'
    },
    {
        label: '审核中',
        value: '2'
    },
    {
        label: '已通过',
        value: '3'
    },
    {
        label: '已驳回',
        value: '4'
    }
];
const projectList = [
    {
        label: '办公室类',
        value: '1'
    },
    {
        label: '展览展示厅',
        value: '2'
    },
    {
        label: '厂房类',
        value: '3'
    },
    {
        label: '实验室类',
        value: '4'
    },
    {
        label: '医美类',
        value: '5'
    },
    {
        label: '教育类',
        value: '6'
    },
    {
        label: '其他',
        value: '7'
    },
];
const projectAttributes = [
    {
        label: '设计施工一体化',
        value: '1'
    },
    {
        label: '纯设计',
        value: '2'
    },
    {
        label: '纯施工',
        value: '3'
    },
    {
        label: '软装',
        value: '4'
    },
    {
        label: '局部装修',
        value: '5'
    },
];
const contractList = [
    {
        label: '设计合同',
        value: '1'
    },
    {
        label: '施工合同',
        value: '2'
    },
    {
        label: 'ECP总包合同',
        value: '3'
    },
];
export {
    industryList,
    areaList,
    statusList,
    projectList,
    projectAttributes,
    contractList
}