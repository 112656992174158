<template>
    <div></div>
</template>

<script>
// 退单记录
export default {
    name: 'order-record',
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        
    }
}
</script>

<style lang="less" scope>

</style>