<template>
  <div class="container">
    <div class="page-title">
      <h3><i class="line"></i>我发布的需求</h3>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      header-cell-class-name="th-cell-class"
      cell-class-name="td-cell-class"
      v-loading="loading"
    >
      <el-table-column
        prop="clueCode"
        label="需求编号"
        width="80">
        <template slot-scope="scope">
          {{scope.row.clueCode?scope.row.clueCode :'未填写'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="发布时间"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="contactName"
        label="联系人名称"
        width="auto">
        <template slot-scope="scope">
          {{scope.row.contactName?scope.row.contactName :'未填写'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="电话"
        width="150">
        <template slot-scope="scope">
          {{scope.row.phone?scope.row.phone :'未填写'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="area"
        label="面积"
        width="100">
        <template slot-scope="scope">
          {{scope.row.area?scope.row.area :'未填写'}}
        </template>
      </el-table-column>
      <el-table-column
        prop="projectType"
        label="项目类型"
        width="120">
        <template slot-scope="scope">
        <dict-tag :options="dict.type.lbg_project_type" :value="scope.row.projectType"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="requestType"
        label="需求类型"
        width="120">
        <template slot-scope="scope">
        <dict-tag :options="dict.type.lbg_request_type" :value="scope.row.requestType"/>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="siteStatus"-->
<!--        label="场地状态">-->
<!--        <template slot-scope="scope">-->
<!--          <dict-tag :options="dict.type.lbg_site_status" :value="scope.row.siteStatus"/>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <!-- <el-table-column
        prop="clueStatus"
        label="跟进状态"
        width="120">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.lbg_clue_status" :value="scope.row.clueStatus"/>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="status"
        label="当前状态"
        width="100">
        <template slot-scope="scope">
          <!--row.clueStatus ==7 商机-->
          <span v-if="scope.row.orderCloseStatus == 1">
            进行中
          </span>
          <span v-else-if="scope.row.orderCloseStatus == 2">
            已完成
          </span>
          <span v-else-if="scope.row.orderCloseStatus == 3">
            已关闭
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="clueStatus"
        label="操作"
        width="80">
        <template slot-scope="scope">
          <el-button type="text" @click="toDetail(scope.row)" class="blue">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        background
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {businessOpportunity} from "@/api/demand.js";
  // 抢单大厅
  export default {
    dicts: ['lbg_request_type','lbg_project_type','lbg_site_status','lbg_site_type','lbg_docking_type','lbg_clue_status'],
    name: 'business-order',
    data() {
      return {
        tableData: [],
        page: 1,
        size: 10,
        total: 0,
        loading: true
      }
    },
    created() {

    },
    mounted() {
      this.queryList()
      // let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      // let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      // this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
    },
    methods: {
      /**
       * 查看
       */
      toDetail(row){
        //7跳转商机  其它跳转线索详情
        if(row.clueStatus == 7){
          this.$router.push({
            path:'/business/demandDetail',
            query: {
              projectName:row.projectName,
              ownerName:row.ownerName,
              customerId:row.customerId,
              customerFirmId:row.customerFirmId,
              id:row.oppId
            },
          })
        }else{
          this.$router.push({
            path:'/business/plateDetail',
            query:{
              id:row.id
            }
          })
        }
      },
      queryList(){
        this.loading = true;
        this.$api.get('/customerApi/clue/list', {
          pageNum: this.page,
          pageSize: this.size,
        })
          .then(res => {
            if(res.code == '200') {
              this.tableData=[...res.rows]
              this.total=res.total
            }
            this.loading = false;
          })
      },
      check(scope){
        this.$router.push({
          path:'/business/demandDetail',
          query: {projectName:this.tableData[scope.$index].projectName,
            ownerName:this.tableData[scope.$index].ownerName,
            customerId:this.tableData[scope.$index].customerId,
            customerFirmId:this.tableData[scope.$index].customerFirmId,
            id:this.tableData[scope.$index].id
          },
        })
      },
      handleCurrentChange(value) {
        this.page = value;
        this.queryList();
      }
    }
  }
</script>

<style lang="less" scope>
.el-table {
  border: 1px solid #EFEFEF;
  color: #575C6F;

  .th-cell-class {
    height: 40px;
    padding: 0;
    background: #EFEFEF !important;
    color: #A7A8AD;
  }
  .td-cell-class {
    padding: 0;
    height: 50px;
    color: #575C6F;
  }
}
</style>
