import Vue from 'vue'
import DataDict from '@/utils/dict'
import req from '@/api/index'

function install() {
  Vue.use(DataDict, {
    metas: {
      '*': {
        labelField: 'dictLabel',
        valueField: 'dictValue',
        request(dictMeta) {
          return req.getRes({
            url: '/system/dict/data/type/' + dictMeta.type,
            method: 'get'
          })
        },
      },
    },
  })
}

export default {
  install,
}
