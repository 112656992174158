<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	components: {
	},
	data() {
		return {
			
		};
    },
	created() {
	},
  mounted(){
    // let token=this.getParamQueryString(window.location.href, 'token')
    // if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == ''){
    //   if (token!=''||token!=undefined||token!=null){
    //     console.log(token)
    //
    //   } else{
    //     this.$router.push('/login')
    //   }
    // }

  },
    methods: {
      getParamQueryString (url, name) {
        let subStr = name + '='
        if (url.indexOf(subStr) !== -1) {
          let arr = url.split(subStr)
          let sub1 = arr[1]
          if (sub1.indexOf('&') !== -1) {
            let arr2 = sub1.split('&')
            let sub2 = arr2[0]
            return sub2
          } else {
            return sub1
          }
        }
        return ''
      },
    }
}
</script>

<style lang="less">
@import './assets/css/reset.less';
@import './assets/css/common.less';
#app {
	height: 100%;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	background: #f5f5f5;
}
.container {
	background: #fff;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
	padding: 15px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	border-radius: 4px;
}
.flex-container {
	overflow: hidden;
    display: flex;
	flex-direction: column;
    width: 100%;
    height: 100%;
	
	.header {
		margin-bottom: 20px;
		padding: 20px 10px 10px 20px;
		background: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		border-radius: 4px;
	}
	.content {
		// overflow-y: auto;
		flex: 1;
		padding: 20px;
		background: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		border-radius: 4px;
	}
	.table-handle {
		padding-bottom: 10px;
		text-align: right;
	}
}
.detail-container {
	overflow: hidden;
    display: flex;
	justify-content: space-between;
	width: 100%;
    height: 100%;

	.card-left {
		flex: 1;
		background: #fff;
	}
	.card-right {
		margin-left: 20px;
		width: 300px;
		background: #fff;
	}
}
.page-title {
	height: 30px;
	line-height: 30px;

	.title {
		font-size: 20px;
		font-weight: 600;
	}
	.total {
		font-size: 14px;
		.num {
			color: #365dff;
			font-style: normal;
		}
	}
}
.pagination {
	text-align: right;
}
.el-pagination {
	margin-top: 36px;
}
.blue {
	color: #07A2FF;
}
.yellow {
	color: #F2A017;
}
.black {
	color: #333;
}
.page-title {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;

	h3 {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #333333;

		i {
			margin-right: 5px;
			display: inline-block;
			width: 3px;
			height: 13px;
			background: linear-gradient(360deg, #FAC400 0%, #FF3700 100%);
		}
	}
}
</style>
