<template>
    <div class="detail-container">
      <el-card class="card-left">
        <div slot="header" class="clearfix">
          <span>添加业绩</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>
        <el-form :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="项目名称" prop="title">
                <el-input v-model="form.title" placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目编号" prop="number">
                <el-input v-model="form.number" placeholder="请输入项目编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="建设单位" prop="unit">
                <el-input v-model="form.unit" placeholder="请输入建设单位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="所属行业">
                  <el-select v-model="form.industry" placeholder="请选择所属行业" clearable>
                    <el-option
                      v-for="(item, index) in industryList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="项目地址">
                    <el-select v-model="form.province" placeholder="请选择省" clearable>
                        <el-option
                          v-for="(item, index) in provinceList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" ">
                    <el-select v-model="form.city" placeholder="请选择市" clearable>
                        <el-option
                          v-for="(item, index) in cityList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label=" ">
                    <el-select v-model="form.area" placeholder="请选择区" clearable>
                      <el-option
                        v-for="(item, index) in areaList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-form-item label="详细地址">
                <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="签约日期">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="项目面积">
                <el-input placeholder="请输入项目面积" v-model="form.areas">
                  <template slot="append">㎡</template>
                </el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="6">
              <el-form-item label="项目均价">
                <el-input placeholder="请输入项目均价" v-model="form.price2">
                  <template slot="append">元/㎡</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目周期">
                <el-input placeholder="请输入项目周期" v-model="form.day">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工程金额">
                <el-input placeholder="请输入工程金额" v-model="form.price1">
                  <template slot="append">万元</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="项目类型">
                  <el-select v-model="form.project" placeholder="请选择项目类型" clearable>
                    <el-option
                      v-for="(item, index) in projectList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>  
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目属性">
                <el-select v-model="form.attributes" placeholder="请选择项目属性">
                    <el-option
                      v-for="(item, index) in projectAttributes"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同属性">
                <el-select v-model="form.contract" placeholder="请选择合同属性">
                    <el-option
                      v-for="(item, index) in contractList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="关联案例">
                <el-select v-model="form.case" placeholder="请选择关联案例">
                    <el-option
                      v-for="(item, index) in caseList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="8">
              <el-form-item label="合同第一页">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">上传</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="付款方式页">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">上传</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="最后公章页">
                <el-upload
                  class="upload-demo"
                  drag
                  action="https://jsonplaceholder.typicode.com/posts/"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">上传</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item style="text-align: right;">
            <el-button @click="back">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
</template>

<script>
import { industryList, projectList, projectAttributes, contractList } from '@/utils/variable.js';
export default {
    name: 'result-add',
    components: {},
    data () {
      return {
        industryList: industryList,
        projectList: projectList,
        projectAttributes: projectAttributes,
        contractList: contractList,
        caseList: [],
        provinceList: [
          {
            label: '北京',
            value: '10001'
          }
        ],
        cityList: [
          {
            label: '北京',
            value: '10002'
          }
        ],
        areaList: [
          {
            label: '东城',
            value: '10003'
          }
        ],
        form: {
          title: ''
        },
        rules: {
          title: [
            { required: true, message: '请输入活动名称', trigger: 'blur' }
          ],
        },
        fileList: []
      };
    },
    created() {
    },
    methods: {
      back() {
        this.$router.back();
      },
      save() {},
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      videoPreview() {},
      videoRemove() {},
      videoExceed() {},
      handlePictureCardPreview() {},
      handleRemove() {},
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
}
.case-prompt {
  .prompt-content {
    margin-top: 20px;
    font-size: 14px;
    color: #303133;

    &:first-child {
      margin: 0;
    }
  }
  .prompt-title {
    font-weight: bolder;
    font-size: medium;
  }
  .prompt-item {
    margin: 5px 0;
    color: #606266;
  }
}
</style>