<template>
  <div class="container">
    <div class="page-title">
      <span class="title">账号安全</span>
    </div>
    <p class="binding">账号绑定</p>
    <div class="bind-box">
      <div class="bind-box-left">
        <img src="../../../assets/images/phone.png" alt="">
        <div style="color: rgba(0, 0, 0, 0.5);">手机已绑定</div>
      </div>
      <el-button type="info" class="bind-btn" @click="dialogFormVisible = true">修改绑定手机号</el-button>
    </div>
    <p class="binding">密码修改</p>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="手机号码" prop="phonenumber">
        <el-input class="form-input" v-model="ruleForm.phonenumber"></el-input>
      </el-form-item>
      <el-form-item label="短信验证码" prop="verificationCode">
        <el-input class="form-input" style="width: 50%" v-model="ruleForm.verificationCode"></el-input>
        <el-button type="primary"  v-show="changeShow"  style="float: right" @click="changeGetCode">{{changeYzm}}</el-button>
        <el-button type="primary"  v-show="!changeShow"  style="float: right;width: 98px;" >{{changeCount}}</el-button>
      </el-form-item>
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input type="password" class="form-input" v-model="ruleForm.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" class="form-input" v-model="ruleForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="newPasswordQr">
        <el-input type="password" class="form-input" v-model="ruleForm.newPasswordQr"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePwd('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="修改绑定手机" :visible.sync="dialogFormVisible">
      <el-form :model="form" style="width: 400px;" ref="dialogForm">
        <el-form-item label="新手机号" :label-width="formLabelWidth">
          <el-input class="form-input" v-model="form.phonenumber"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码"  :label-width="formLabelWidth">
          <el-input class="form-input" style="width: 58%" v-model="form.verificationCode"></el-input>
          <el-button type="primary" v-show="show"  style="float: right" @click="getCode">{{yzm}}</el-button>
          <el-button type="primary " v-show="!show"  style="float: right;width: 98px;" >{{count}}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'result-list',
    components: {},
    data () {
      return {
        ruleForm: {
        },
        timer:null,
        changeTimer:null,
        count:'',
        changeCount:'',
        changeYzm:'获取验证码',
        yzm:'获取验证码',
        show: true,
        changeShow: true,
        rules:{
          phonenumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },],
          verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' },],
          oldPassword: [{ required: true, message: '请输入旧密码', trigger: 'blur' },],
          newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' },],
          newPasswordQr: [{ required: true, message: '请确认新密码', trigger: 'blur' },],
        },
        dialogFormVisible: false,
        formLabelWidth: '120px',
        form:{},
      };
    },
    created() {},
    mounted() {

    },
    methods: {
      changePwd(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
           if (this.ruleForm.newPassword!=this.ruleForm.newPasswordQr){
             this.$message.error('两次输入密码不一致');
           }else{
             let form={
               ...this.ruleForm
             }
             delete form['newPasswordQr']
             this.$api.post('/customerApi/customer/editPassword',form)
               .then(res => {
                 if(res.code == '200') {
                   this.$message({
                     message: res.data,
                     type: 'success'
                   });
                 }
               })
           }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      submitForm() {
        let form={
          ...this.form
        }
        this.$api.post('/customerApi/customer/bindPhone',form)
          .then(res => {
            if(res.code == '200') {
              this.dialogFormVisible=false
              this.$message({
                message: res.data,
                type: 'success'
              });
            }
          })
      },
      changeGetCode(){
        let that=this
        if(this.ruleForm.phonenumber==''||this.ruleForm.phonenumber==undefined){
          that.$message.error('请输入手机号');
        }else{
          this.$api.get('/customerApi/customer/getVerificationCode?phone='+this.ruleForm.phonenumber)
            .then(res => {
              if(res.code != '200') {
                that.$message.error(res.msg);
              }else{
                // that.$message({
                //   message: res.data,
                //   type: 'success'
                // });
                const TIME_COUNT = 60;
                if (!that.timer) {
                  that.changeCount = TIME_COUNT;
                  that.changeShow = false;
                  that.changeTimer = setInterval(() => {
                    if (that.changeCount > 0 && that.changeCount <= TIME_COUNT) {
                      that.changeCount--;
                    } else {
                      that.changeShow = true;
                      clearInterval(that.changeTimer);
                      that.changeTimer = null;
                      that.changeYzm="再次获取"
                    }
                  }, 1000)
                }
              }

            })
        }
      },
      getCode(){
        let that=this
        console.log(this.ruleForm.phone)
        if(this.form.phonenumber==''||this.form.phonenumber==undefined){
          that.$message.error('请输入手机号');
        }else{
          this.$api.get('/customerApi/customer/getVerificationCode?phone='+this.form.phonenumber)
            .then(res => {
              if(res.code != '200') {
                that.$message.error(res.msg);
              }else{
                // that.$message({
                //   message: res.data,
                //   type: 'success'
                // });
                const TIME_COUNT = 60;
                if (!that.timer) {
                  that.count = TIME_COUNT;
                  that.show = false;
                  that.timer = setInterval(() => {
                    if (that.count > 0 && that.count <= TIME_COUNT) {
                      that.count--;
                    } else {
                      that.show = true;
                      clearInterval(that.timer);
                      that.timer = null;
                      that.yzm="再次获取"
                    }
                  }, 1000)
                }
              }

            })
          }

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}

</script>
<style lang='less' scoped>
  .page-title{

  }
.binding{
  color: rgb(75, 77, 89);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 20px;
}
.bind-box{
  border-width: 1px;
  border-color: #cacfe4;
  border-style: solid;
  width: 700px;
  height: 100px;
}
  .bind-box-left{
    padding-left: 18px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    float: left;
    margin-top: 30px;
    img{
      margin-right: 12px;
    }
    div{
      line-height: 40px;
    }
  }
  .bind-btn{
    margin-right: 18px;
    line-height: 1.05;
    float: right;
    margin-top: 30px;
  }
  .demo-ruleForm{
    width: 350px;
  }
  .form-input{
    width: 100%;
    height: 40px;
  }
</style>
