<template>
  <div style="margin-top: 78px;display: flex;align-items: center;justify-content: center;flex-direction: column">
    <img src="../../../../assets/firm/ok.png"  style="width: 180px;height: 176px">
    <div style="font-size: 14px;font-weight: 500;margin-top: 46px">
      <span style="color: #575C6F">提交成功！</span>
      <span style="color: #999999">审核需要</span>
      <span style="color: #E94B22">1</span>
      <span style="color: #999999">个工作日，请耐心等待！</span>
    </div>
    <div class="close-btn">关闭</div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="less">
.close-btn{
  width: 158px;
  height: 35px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #EFEFEF;
  line-height: 35px;
  text-align: center;
  margin-top: 51px;
  cursor: pointer;
}
</style>
