<template>
  <div class="container">
    <div class="page-title">
      <span class="title">项目需求管理</span>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        prop="businessCode"
        label="商机编号">
      </el-table-column>
      <el-table-column
        prop="businessPlatformStatus"
        label="需求进度">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.business_platform_status" :value="scope.row.businessPlatformStatus"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="projectName"
        label="项目名称">
      </el-table-column>
      <el-table-column
        prop="projectType"
        label="项目类型">

      </el-table-column>
      <el-table-column
        prop="status"
        label="需求类型">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.lbg_request_type" :value="scope.row.demandType"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="surveyTime"
        label="勘测时间">
      </el-table-column>
      <el-table-column
        prop="bidDistributionTime"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="storeNum"
        label="应标商家数">
        <template slot-scope="scope">
          <p>{{scope.row.enterpriseNum}}/{{scope.row.bidMax}}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="操作"
        fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="check(scope)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="size"
      background
      style="float: right"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
  import {businessOpportunity} from "@/api/demand.js";
// 抢单大厅
export default {
  dicts: ['lbg_request_type','lbg_project_type','business_platform_status'],
    name: 'business-order',
    data() {
        return {
          tableData: [],
          page: 1,
          size: 10,
          total: 0,
        }
    },
    created() {

    },
  mounted() {
      this.queryList()
    // let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
    // let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
    // this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
  },
    methods: {
      queryList(){
        this.$api.get('/customerApi/businessOpportunity/list')
          .then(res => {
            if(res.code == '200') {
              this.tableData=[...res.rows]
              this.total=res.total
            }
          })
     },
      check(scope){
        this.$router.push({
          path:'/business/demandDetail',
          query: {projectName:this.tableData[scope.$index].projectName,
            ownerName:this.tableData[scope.$index].ownerName,
            customerId:this.tableData[scope.$index].customerId,
            customerFirmId:this.tableData[scope.$index].customerFirmId,
          id:this.tableData[scope.$index].id
          },
        })
      },
      handleSizeChange() {},
      handleCurrentChange() {}
    }
}
</script>

<style lang="less" scope>

</style>
