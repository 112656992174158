<template>
  <div>
    <div class="form-box">
      <el-form label-position="top" label-width="80px" :model="form">
        <el-form-item label="企业名称">
          <el-input v-model="form.name" style="width: 380px" placeholder="必须填写营业执照上的企业名称全称"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码">
          <el-input v-model="form.region" style="width: 380px" placeholder="必须为13位或18位数字和字母"></el-input>
        </el-form-item>
        <el-form-item label="所在行业">
          <el-input v-model="form.type" style="width: 380px" placeholder="请选择"></el-input>
        </el-form-item>
        <el-form-item label="办公地址">
          <el-input v-model="form.type" style="width: 380px" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="营业执照上传">
          <div class="yyzz-img">
            <span>+</span>
            <span>上传营业执照照片</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="submit-btn">提交</div>
    </div>


  </div>
</template>

<script>
export default {
  data(){
    return{
      form:{},
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="less">
.form-box{
  margin-top: 57px;
  padding: 5px;
}
.yyzz-img{
  width: 380px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #E94B22;
  line-height: 40px;
  padding-left: 15px;
  &:hover{
    cursor: pointer;
  }
}
.submit-btn{
  width: 112px;
  height: 35px;
  background: #E94B22;
  border-radius: 3px;
  border: 1px solid #E5EAED;
  text-align: center;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 35px;
  margin-top: 38px;
}
/deep/.el-form-item__label{
  color: #9A9DA9;
}
</style>
