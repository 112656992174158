<template>
  <div class="container">
    <div class="page-title">
      <span class="title">我的订单</span>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="projectName"
        label="项目名称"
        width="auto">
      </el-table-column>
      <el-table-column
        prop="recommend"
        label="签约类型">
      </el-table-column>
      <el-table-column
        prop="signTime"
        label="签约日期">
      </el-table-column>
      <el-table-column
        prop="businessStartTime"
        label="开工日期">
      </el-table-column>
      <el-table-column
        prop="businessFinishTime"
        label="完工日期">
      </el-table-column>
      <el-table-column
        prop="enterpriseName"
        label="服务商名称">
      </el-table-column>
      <el-table-column
        prop="createBy"
        label="记录上传人">
      </el-table-column>
      <el-table-column
        prop="status"
        label="操作"
      width="150"
      align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleDetail(scope.row)">查看</el-button>
<!--          <el-button-->
<!--            size="mini"-->
<!--            type="primary"-->
<!--            @click="handleEdit(scope.row)">编辑</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      style="float: right"
      :page-size="size"
      background
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>


    <el-dialog :title="title" width="1000px;" :visible.sync="dialogFormVisible">
      <el-row>
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-col :span="12">
            <el-form-item label="合同编号" prop="signNum" :label-width="formLabelWidth">
              <el-input v-model="form.signNum" :disabled="disabled" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属客户"  :label-width="formLabelWidth">
              <el-input v-model="form.customerName" disabled autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目名称"  :label-width="formLabelWidth">
              <el-input v-model="form.projectName" disabled autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务商名称" prop="enterpriseId" :label-width="formLabelWidth">
              <el-select style="width: 100%;" :disabled="disabled" v-model="form.enterpriseId" placeholder="请选择服务商家名称">
                <el-option v-for="(item,index) in enterprise" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同类型"  :label-width="formLabelWidth">
              <el-select style="width: 100%;" disabled v-model="form.region" placeholder="请选择活动区域">
                <el-option label="设计合同" value="1"></el-option>
                <el-option label="施工合同" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约金额(元)" prop="contractAmount" :label-width="formLabelWidth">
              <el-input v-model="form.contractAmount" :disabled="disabled"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开工日期"  prop="businessStartTime" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 100%;"
                v-model="form.businessStartTime"
                :disabled="disabled"
                type="date"
                value-format="yyyy-MM-dd"
                :clearable="false"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="完工日期" prop="businessFinishTime" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 100%;"
                v-model="form.businessFinishTime"
                type="date"
                value-format="yyyy-MM-dd"
                :clearable="false"
                :disabled="disabled"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约日期"  prop="signTime" :label-width="formLabelWidth">
              <el-date-picker
                style="width: 100%;"
                v-model="form.signTime"
                :disabled="disabled"
                :clearable="false"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注"  :label-width="formLabelWidth">
              <el-input
                type="textarea"
                :disabled="disabled"
                :rows="2"
                placeholder="请输入内容"
                v-model="form.remarks">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="合同附件" prop="coverFileList" :label-width="formLabelWidth">
              <p v-if="disabled">{{form.file}}</p>
              <el-upload
                :action="uploadFileUrl"
                :headers="headers"
                v-if="!disabled"
                :on-success="handleCoverUploadSuccess"
                :on-remove="handleCoverUploadRemove"
                :file-list="form.coverFileList"
                :on-exceed="exceed"
                :limit="1"
              >
                <!--<i class="el-icon-plus"></i>-->
                <el-button size="small" v-if="!disabled">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible=false" v-if="disabled">确定</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="!disabled">确定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
  import { getToken } from '@/utils/auth'
  export default {
    name: 'order-wallet',
    data() {
      return {
        dialogFormVisible: false,
        title: '',
        tableData: [],
        page: 1,
        size: 10,
        disabled:false,
        total: 0,
        form: {
          coverFileList:[]
        },
        formLabelWidth: '120px',
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken()
        },
        rules: {
          signNum: [{required: true, message: '请输入合同编号', trigger: 'blur'},],
          enterpriseId: [
            {required: true, message: '请选择服务商名称', trigger: 'change'}
          ],
          coverFileList: [
            {required: true, message: '请上传合同附件', trigger: 'change'}
          ],
        },
        enterprise:[],
      }
    },
    created() {

    },
    mounted() {
      this.queryList()
      this.queryEnterprise()
    },
    methods: {
      queryList(){
        this.$api.get('/customerApi/contractSignRecord/list')
          .then(res => {
            if(res.code == '200') {
              this.tableData=[...res.rows]
              this.total=res.total
            }
          })
      },
      submitForm(formName) {
        console.log(this.form)
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let form={
              ...this.form
            }
            delete form['createTime']
            this.$api.put('/customerApi/contractSignRecord',form)
              .then(res => {
                if(res.code == '200') {
                  this.$message.success(res.msg);
                  this.dialogFormVisible=false
                  this.queryList()
                }
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      exceed(){
        this.$message.error(`只允许上传一个附件!`);
      },
      queryEnterprise(){
        this.$api.get('/customerApi/enterprise/list')
          .then(res => {
            if(res.code == '200') {
              this.enterprise=[...res.rows]
            }
          })
      },
      handleCoverUploadSuccess(res, file, fileList) {
        this.signForm.coverFileList = [];
        if(res.code == 200) {
          this.signForm.coverFileList.push(res.data);
          // this.form.coverFileId = res.data.fileId;
          this.$message.success("上传成功");
        } else {
          this.signForm.coverFileList = [];
          this.$message.error(res.msg);
        }
      },
      handleCoverUploadRemove(file, fileList) {
        this.signForm.coverFileList = [];
      },
      handleEdit(record){
        this.dialogFormVisible = true;
        this.title='修改订单信息'
        this.disabled=false
        this.form={
          ...record,
          coverFileList:[]
        }
        this.form.coverFileList.push({url:record.contractFileUrl,name:record.contractFileUrl})
      },
      handleDetail(record) {
        this.form={
          ...record,
          coverFileList:[]
        }
        console.log(record.contractFileUrl)
        this.form.coverFileList.push({url:record.contractFileUrl,name:record.contractFileUrl})
        this.form.file=record.contractFileUrl
        console.log(this.form.coverFileList)
        this.dialogFormVisible = true;
        this.title='订单详情'
        this.disabled=true
        // this.detail = record
      },
      handleSizeChange() {},
      handleCurrentChange() {}
    }
  }
</script>

<style lang="less" scope>

</style>
