import request from '@/api/index'

// 查询省份列表
export function listProvince(query) {
  return request.getRes({
    url: '/lbg/province/list',
    method: 'get',
    params: query
  })
}

// 查询省份详细
export function getProvince(id) {
  return request.getRes({
    url: '/lbg/province/' + id,
    method: 'get'
  })
}

// 新增省份
export function addProvince(data) {
  return request.getRes({
    url: '/lbg/province',
    method: 'post',
    data: data
  })
}

// 修改省份
export function updateProvince(data) {
  return request.getRes({
    url: '/lbg/province',
    method: 'put',
    data: data
  })
}

// 删除省份
export function delProvince(id) {
  return request.getRes({
    url: '/lbg/province/' + id,
    method: 'delete'
  })
}
