<template>
  <el-cascader
      size="small"
      :props="props"
      v-model="areaArray"
      @change="handleChange">
    </el-cascader>
</template>

<script>
// 只用方法：
// 1、通过 import 的方式将组件引入
// 2、指明 areaData 事件方法用于获取选中数据后的事件回调，参数会返回省id provinceId、市id cityId,区域id districtId 例：
// <area-select @areaData="getAreaData" ref="areaSelect"></area-select>
// function getAreaData(provinceId, cityId, districtId){
// 获取到参数后的处理逻辑
// }
// 3、如果需要清空该组件数据，请调通组件中 clearData 方法即可，例 this.$refs.areaSelect.clearData()
// 4、如果需要设置初始值，请调用 setData 方法， 例 this.$refs.areaSelect.setData(provinceId, cityId, districtId)
import { listProvince } from '../../api/area/province.js'
import { listCity } from '../../api/area/city'
import { listDistrict } from '../../api/area/district'
export default {
  name: 'areaSelect',
  props: {
      valueKey: {
        type: String,
        default: 'value'
      },
      level: {
        type: Number,
        default: 3
      }
  },
  data() {
    return {
        areaArray: [],
        props: { // 级联选择器的属性配置

        },
    }
  },
  created() {
      let that = this;
      this.props = {
           lazy: true,
            value: this.valueKey,
            // children: 'cities',
            lazyLoad (node, resolve) {
                // if (!that.firstNode || !that.firstResolve) {
                //     if (node.level === 0) {
                //         // debugger
                //         that.firstNode = node
                //         that.firstResolve = resolve
                //     }
                //     return
                // }
                that.generateData(node, resolve);
            }
      }
  },
  methods: {
      handleChange(value){
          console.log(value)
          let provinceId = value[0];
          let cityId = value[1]-1;
          let districtId = value[2]-2;
          this.$emit('areaData', provinceId, cityId, districtId)
      },
      // 清空选择的数据
      clearData(){
          this.areaArray = '';
          this.$emit('areaData', undefined, undefined, undefined)
      },
      // 设置数据初始值
      setData(provinceId, cityId, districtId){
          this.areaArray[0] = provinceId;
          this.areaArray[1] = cityId;
          this.areaArray[2] = districtId;
          console.log(this.areaArray)
      },
      // 加载节点数据
      generateData(node, resolve){
        const { level, value } = node
        let flag = level + 1;
        if(level === 0){
            listProvince().then(res => {
                const nodes = res.rows.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                        children: []
                    }
                })
                resolve(nodes);
            });
        }else if(level === 1 && this.level >= 2){

            // 加载城市数据
            listCity({provinceId:value}).then(res => {
                const nodes = res.rows.map(item => ({
                    value: item.id+1,
                    label: item.name,
                    leaf: this.level === 2
                }));
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
            });
        }else if(level === 2 && this.level === 3){
            listDistrict({cityId:value-1}).then(res => {
                const nodes = res.rows.map(item => ({
                    value: item.id+2,
                    label: item.name,
                    leaf: true
                }));
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
            });
        }else{
            resolve()
        }
      }
  }
}
</script>

<style lang="scss" scoped>
</style>
