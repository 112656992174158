<template>
    <el-container id="app">
		<el-aside :width="collapse ? '64px' : '200px'">
			<el-header class="flex justify-center align-center">
        <img src="../../assets/index-logo.png" style="width: 160px;height: 20px" alt="">
				<!--<img v-if="collapse" src="../../assets/images/logo.png" alt="" width="30" height="30">-->
				<!--<span v-else>狸办公</span>-->
			</el-header>
			<el-menu class="el-menu-vertical-demo" :collapse="collapse" :collapse-transition="true" :unique-opened="true" router :default-active="active">
				<el-menu-item index="/index/index">
					<i class="el-icon-s-home"></i>
					<span slot="title">首页</span>
				</el-menu-item>
				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-s-shop"></i>
						<span slot="title">我的需求</span>
					</template>
<!--					<el-menu-item index="/business/order">项目需求管理</el-menu-item>-->
					<el-menu-item index="/business/platform">我发布的需求</el-menu-item>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-s-order"></i>
						<span slot="title">订单管理</span>
					</template>
					<el-menu-item index="/order/wallet">我的订单</el-menu-item>
					<!--<el-menu-item index="/order/trade">商机宝</el-menu-item>-->
					<!--<el-menu-item index="/order/contract">签约管理</el-menu-item>-->
					<!--<el-menu-item index="/order/record">退单记录</el-menu-item>-->
				</el-submenu>
				<el-submenu index="4">
					<template slot="title">
						<i class="el-icon-s-shop"></i>
						<span slot="title">信息设置</span>
					</template>
          <el-menu-item index="/store/firm/index">企业信息</el-menu-item>
					<el-menu-item index="/store/case/list">个人信息</el-menu-item>
					<el-menu-item index="/store/result/list">账号安全</el-menu-item>
					<!--<el-menu-item index="/store/product">品宣管理</el-menu-item>-->
				</el-submenu>
				<!--<el-submenu index="5">-->
					<!--<template slot="title">-->
						<!--<i class="el-icon-s-shop"></i>-->
						<!--<span slot="title">公司管理</span>-->
					<!--</template>-->
					<!--<el-menu-item index="/company/basic">商户基本信息</el-menu-item>-->
					<!--<el-menu-item index="/company/account">人员管理</el-menu-item>-->
				<!--</el-submenu>-->
			</el-menu>
		</el-aside>
		<el-container>
			<el-header>
				<div class="header-flex">
          <div class="back-home" @click="goBack()">返回首页</div>
					<i class="collapse" :class="$store.state.collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="change"></i>
					<el-menu class="el-menu-demo" mode="horizontal">
						<el-submenu index="2">
							<template slot="title">我的工作台</template>
							<el-menu-item index="2-1" @click="quit">退出系统</el-menu-item>
							<!-- <el-menu-item index="2-1" @click="goBack">返回首页</el-menu-item> -->
						</el-submenu>
					</el-menu>
				</div>
			</el-header>
			<el-main>
				<router-view/>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import {getUserName} from "../../utils/auth.js";
import {getToken} from "../../utils/auth.js";

export default {
    name: '',
    data() {
        return {
            active: '/index/index',
			isCollapse: false,
          collapse:false
        }
    },
    created() {
        this.active = this.$route.path;
    },
    methods: {
      change(){
        this.collapse = !this.collapse
      },
      goBack(){
        window.location.href= `${process.env.VUE_APP_CUSTOMER_URL}?token=${getToken()}&userName=${getUserName()}`
      },
        quit() {
			this.$confirm('确定注销并退出系统吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$store.dispatch('LogOut').then(() => {
					location.href = '/login';
				})
			}).catch(() => {});
		}
    }
}
</script>

<style lang="less" scoped>
.back-home{
  font-size: 14px;
  color: #909399;
  &:hover{
    cursor: pointer;
    color: #000000;
  }
}
.el-header, .el-footer {
	padding: 0;
	background-color: #fff;
	color: #333;
	line-height: 60px;
}
.is-active{
  color: #E94C21 !important;
}
.is-opened{
  .is-active{
    color: #E94C21 !important;
    background: #FFEBE5 !important;
    border-right: 4px solid #E94C21;
  }
}

.el-aside {
	background-color: #fff;
	color: #333;
	line-height: 200px;
}
.el-submenu__title:hover{
  background: #FFEBE5 !important;
}
.el-menu-item:focus{
  background: #FFEBE5 !important;
}
.el-submenu{
  /deep/ .el-submenu__title:hover{
    background: #FFEBE5 !important;
  }
}

.el-menu-item:hover{
  background: #FFEBE5 !important;
}
.el-main {
	background-color: #E9EEF3;
	color: #333;
	padding: 10px;
}
.collapse {
	font-size: 20px;
	cursor: pointer;
  visibility: hidden;
}
.header-flex {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.el-aside{
  // 侧边栏折叠动画速度
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
}
</style>
