<template>
  <div>
    <dict-tag :options="options" :value="value" v-if="value"/>
    <span v-else>未填写</span>
  </div>
</template>

<script>
export default {
  dicts: ['lbg_request_type', 'lbg_project_type', 'lbg_site_status', 'lbg_site_type', 'business_platform_status', 'lbg_enterprise_service_type', 'firm_trade', 'firm_type', 'bidding_status','lost_reason','invalid_reason'],
  data(){
    return{
    }
  },
  props: {
    options: {
      type: Array,
      default: null,
    },
    value: [Number, String, Array],
  },
  methods:{

  },//methods
}
</script>

<style scoped>

</style>
