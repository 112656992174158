<template>
    <div class="container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="1">
                <el-form :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="公司logo">
                                <el-upload
                                    class="avatar-uploader"
                                    action="https://jsonplaceholder.typicode.com/posts/"
                                    :show-file-list="false"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <div class="el-upload__tip">logo尺寸：200×200像素,仅支持jpg,PNG.jpeg图片文件,文件小于100KB</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="公司全称" prop="title">
                                <el-input v-model="form.title" placeholder="请输入公司全称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="公司简称" prop="number">
                                <el-input v-model="form.number" placeholder="请输入公司简称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="slogan" prop="unit">
                                <el-input v-model="form.unit" placeholder="请输入slogan"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-form-item label="办公面积">
                                <el-input placeholder="请输入办公面积" v-model="form.areas">
                                <template slot="append">㎡</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        
                        <el-col :span="8">
                            <el-form-item label="联系人" prop="contact">
                                <el-input v-model="form.contact" placeholder="请输入联系人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="管理员手机" prop="mobile">
                                <el-input v-model="form.contact" placeholder="请输入管理员手机"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <el-row :gutter="10">
                                <el-col :span="8">
                                <el-form-item label="项目地址">
                                    <el-select v-model="form.province" placeholder="请选择省" clearable>
                                        <el-option
                                        v-for="(item, index) in provinceList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                <el-form-item label=" ">
                                    <el-select v-model="form.city" placeholder="请选择市" clearable>
                                        <el-option
                                        v-for="(item, index) in cityList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                <el-form-item label=" ">
                                    <el-select v-model="form.area" placeholder="请选择区" clearable>
                                    <el-option
                                        v-for="(item, index) in areaList"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                    </el-select>
                                </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="详细地址">
                                <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
                            </el-form-item>
                        </el-col>
                         <el-col :span="8"></el-col>
                    </el-row>

                    <el-form-item style="text-align: center; margin-top: 20px;">
                        <el-button @click="back">取消</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
                    </el-form-item>
                    </el-form>
            </el-tab-pane>
            <el-tab-pane label="业务设置" name="2">配置管理</el-tab-pane>
            <el-tab-pane label="实名认证" name="3">角色管理</el-tab-pane>
            <el-tab-pane label="企业深度认证" name="4">定时任务补偿</el-tab-pane>
            <el-tab-pane label="分公司设置" name="5">定时任务补偿</el-tab-pane>
            <el-tab-pane label="安全设置" name="6">定时任务补偿</el-tab-pane>
        </el-tabs>
        
    </div>
</template>

<script>
export default {
    name: 'company-basic',
    components: {},
    data () {
        return {
            activeName: '1',
            isEdit: true,
            form: {
                title: ''
            },
            imageUrl: '',
            rules: {
                title: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' }
                ],
            },
            provinceList: [
                {
                    label: '北京',
                    value: '10001'
                }
            ],
            cityList: [
                {
                    label: '北京',
                    value: '10002'
                }
            ],
            areaList: [
                {
                    label: '东城',
                    value: '10003'
                }
            ],
        };
    },
    created() {},
    methods: {
        handleClick() {},
        back() {},
        handleAvatarSuccess() {},
        beforeAvatarUpload() {},
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .el-tabs__content {
        height: 100%;
        overflow-y: scroll;
    }
}
::v-deep .form-inline {
  // padding-left: 10px;
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      height: 40px;
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
}
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
}
.avatar-uploader:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.avatar {
    width: 60px;
    height: 60px;
    display: block;
}
</style>