<template>
  <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;margin-top: 122px">
    <img src="../../../../assets/firm/wrz.png" style="width: 200px;height: 195px">
    <div class="join-firm-btn">立即认证/加入企业</div>
  </div>
</template>

<script>
export default {
  name: "notAuth"
}
</script>

<style scoped lang="less">
.join-firm-btn{
  width: 160px;
  height: 35px;
  background: #E94B22;
  border-radius: 3px;
  border: 1px solid #E5EAED;
  line-height: 35px;
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  &:hover{
    cursor: pointer;
  }
}
</style>
