import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'
import Api2 from '@/api/index.js';

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(state.token)
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      console.log(userInfo);

      return new Promise((resolve, reject) => {
        // login(username, password, code, uuid).then(res => {
        //   let data = res.data
        //   setToken(data.access_token)
        //   commit('SET_TOKEN', data.access_token)
        //   commit('SET_EXPIRES_IN', data.expires_in)
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        // getInfo().then(res => {
        //   const user = res.user
        //   const avatar = user.avatar == "" ? require("@/assets/images/profile.jpg") : user.avatar;
        //   if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
        //     commit('SET_ROLES', res.roles)
        //     commit('SET_PERMISSIONS', res.permissions)
        //   } else {
        //     commit('SET_ROLES', ['ROLE_DEFAULT'])
        //   }
        //   commit('SET_NAME', user.userName)
        //   commit('SET_AVATAR', avatar)
        //   resolve(res)
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 刷新token
    RefreshToken({commit, state}) {
      return new Promise((resolve, reject) => {
        // refreshToken(state.token).then(res => {
        //   commit('SET_EXPIRES_IN', res.data)
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      //调用接口将用户缓存删除
      Api2.post('/customerApi/logout')
      .then(res => {
          if(res.code == '200') {
            //  console.log(res);
             return new Promise((resolve, reject) => {
              commit('SET_TOKEN', '')
              removeToken()
              resolve()
            })

          }
        })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      console.log("前端 登出");
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
