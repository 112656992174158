<template>
  <div class="container">
    <div class="flex-center-s firm-title">
      <div class="gradual-line"></div>
      <div style="padding-left: 5px;font-size: 16px;font-weight: 500">企业信息</div>
    </div>
    <div v-if="1==2">
      <div class="auth-step-box">
        <div>
          <div class="node" v-if="1==1"><div class="node-active"></div></div>
          <div class="node-gray" v-else></div>
          <div class="step-text">信息填写</div>
        </div>
        <div>
          <div class="node" v-if="1==2"><div class="node-active"></div></div>
          <div class="node-gray" v-else></div>
          <div class="step-text">认证中</div>
        </div>
        <div>
          <div class="node" v-if="1==2"><div class="node-active"></div></div>
          <div class="node-gray" v-else></div>
          <div class="step-text">完成认证</div>
        </div>
      </div>
      <div class="auth-step-line-box">
        <div class="node-line" :style="{'background-color':1==2?'#E94B22':''}"></div>
        <div class="node-line"></div>
      </div>
    </div>
    <notAuth v-if="1==1"></notAuth>
    <firmAuth v-if="1==2"></firmAuth>
    <authSuccess v-if="1==2"></authSuccess>
    <authFail v-if="1==2"></authFail>
  </div>
</template>

<script>
import notAuth from "./components/notAuth.vue";
import firmAuth from "./components/firmAuth.vue";
import authSuccess from "./components/authSuccess.vue";
import authFail from "./components/authFail.vue";
export default {
  components:{notAuth,firmAuth,authSuccess,authFail},
  name: "index"
}
</script>

<style scoped lang="less">
.flex-center-s{
  display: flex;
  align-items: center;
}
.gradual-line{
  width: 3px;
  height: 13px;
  background: linear-gradient(360deg, #FAC400 0%, #FF3700 100%);
}
.firm-title{
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 14px;
}


.auth-step-box,.auth-step-line-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;
  margin-left: 216px;
  margin-right: 216px;
  position: relative;
}
.auth-step-box{
  &>div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.step-text{
  position: absolute;
  top: 20px;
  width: 8ch;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.auth-step-line-box{
  &>div{
    width: 50%;
    height: 1px;
    background-color: #CBCFE4;
    position: absolute;
    top: -43px;
  }
  &>div:nth-child(2){
    left: (50%);
  }
}
.node{
  width: 12px;
  height: 12px;
  background: #E94B22;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.node-gray{
  width: 5px;
  height: 5px;
  background: #CBCFE4;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.node-active{
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}
</style>
