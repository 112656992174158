<template>
    <div></div>
</template>

<script>
// 签约管理
export default {
    name: 'order-contract',
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        
    }
}
</script>

<style lang="less" scope>

</style>