<template>
  <div class="flex-container">
    <div class="header" ref="header">
      <el-form :inline="true" :model="formInline" class="form-inline">
        <el-row>
          <el-col :span="4">
             <el-form-item label-width="0">
              <el-input v-model="formInline.title" placeholder="请输入关键词"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-select v-model="formInline.recommend" placeholder="职称" clearable>
                <el-option label="首页推荐" value="1"></el-option>
                <el-option label="编辑推荐" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-select v-model="formInline.status" placeholder="审核状态" clearable>
                <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label-width="0">
              <el-button type="primary" @click="onSubmit">查询</el-button>
              <el-button @click="onSubmit">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <div class="table-handle">
        <el-button size="small" type="primary" @click="add">添加人员</el-button>
        <el-button size="small">删除</el-button>
      </div>
      <el-table
        v-if="tableHeight"
        :data="tableData"
        style="width: 100%"
        :max-height="tableHeight"
      >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
              prop="url"
              label="头像审核状态"
              width="150">
          </el-table-column>
          <el-table-column
              prop="title"
              label="姓名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="用户名"
              width="100">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="手机号"
              width="120">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="职称"
              width="100">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="从业年限"
              width="100">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="角色"
              width="100">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="创建时间"
              width="180">
          </el-table-column>
          <el-table-column
              prop="recommend"
              label="任职状态"
              width="100">
          </el-table-column>
          <el-table-column
              prop="status"
              label="审核状态"
              width="100">
          </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { industryList, areaList, statusList } from '@/utils/variable';
export default {
    name: 'company-account',
    components: {},
    data () {
      return {
        industryList: industryList,
        areaList: areaList,
        statusList: statusList,
        page: 1,
        size: 20,
        total: 0,
        formInline: {
          title: '',
          recommend: '',
          industry: '',
          area: '',
          designer: '',
          status: ''
        },
        tableData: [
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
          {
            title: '11'
          },
        ],
        tableHeight: ''
      };
    },
    created() {},
    mounted() {
      let searchFormHeight = this.$refs['header'].clientHeight + 20 // 可变的查询高度
      let pageHeight = document.documentElement.clientHeight // 可用窗口的高度
      this.tableHeight = Number(pageHeight - (searchFormHeight + 100 + 130));
    },
    methods: {
      add() {
        this.$router.push({
          path: '/store/case/add',
          query: {id: '1'}
        });
      },
      onSubmit() {},
      handleSizeChange() {},
      handleCurrentChange() {}
    }
}

</script>
<style lang='less' scoped>
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-bottom: 10px !important;
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>