<template>
    <div class="detail-container">
      <el-card class="card-left">
        <div slot="header" class="clearfix">
          <span>添加案例</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="back">返回</el-button>
        </div>
        <el-form :inline="true" :model="form" class="form-inline" label-position="right" :rules="rules" ref="ruleForm">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="项目名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目地址" prop="area">
                <el-input v-model="form.area" placeholder="请输入项目地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属行业" prop="industry">
                  <el-select v-model="form.industry" placeholder="请选择所属行业" clearable>
                    <el-option
                      v-for="(item, index) in industryList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="项目面积">
                <el-input placeholder="请输入项目面积" v-model="form.area">
                  <template slot="append">㎡</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目周期">
                <el-input placeholder="请输入项目周期" v-model="form.day">
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目类型" prop="projectType">
                  <el-select v-model="form.projectType" placeholder="请选择项目类型" clearable>
                    <el-option
                      v-for="(item, index) in projectList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="工程造价">
                <el-input placeholder="请输入工程造价" v-model="form.price1">
                  <template slot="append">万元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目均价">
                <el-input placeholder="请输入项目均价" v-model="form.price2">
                  <template slot="append">元/㎡</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目属性" prop="attributes">
                  <el-select v-model="form.attributes" placeholder="请选择项目属性">
                    <el-option
                      v-for="(item, index) in projectAttributes"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="团队设置">
                  <el-button icon="el-icon-plus" circle @click="showDialog"></el-button>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="项目概况" prop="desc">
                <el-input 
                  type="textarea"
                  v-model="form.desc"
                  placeholder="请添加导语内容（可以描述业主需求和期望，设计师的设计理念,不少于50字符）"
                  maxlength="150"
                  show-word-limit
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="上传视频">
                  <el-upload
                    class="avatar-uploader el-upload--text"
                    :action="uploadFileUrl"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="handleVideoSuccess"
                    :before-upload="beforeUploadVideo"
                    :on-progress="uploadVideoProcess">
                      <video v-if="videoForm.Video !='' && videoFlag == false" :src="videoForm.Video" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
                      <i v-else-if="videoForm.Video =='' && videoFlag == false" class="el-icon-plus avatar-uploader-icon"></i>
                      <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top:30px;"></el-progress>
                      <div slot="tip" class="el-upload__tip">推荐上传最大不超过1G的mp4视频 建议宽高比为16:9，时长不超过60秒</div>
                      <div class="mask"><i class="el-icon-upload"></i></div>
                  </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="原始平面图">
                <el-upload
                  :action="uploadFileUrl"
                  :before-upload="handleBeforeUpload"
                  :headers="headers"
                  multiple
                  accept=".jpeg, .png, .jpg"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-success="handleUploadSuccess"
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">可多图上传，单张图片最大支持10M</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="平面方案图">
                <el-upload
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">可多图上传，单张图片最大支持10M</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item class="is-required">
                <label slot="label" class="el-form-item__label">方案效果图</label>
                <el-upload
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">可多图上传，单张图片最大支持10M</div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item class="is-required">
                <label slot="label" class="el-form-item__label">项目竣工实景图</label>
                <el-upload
                  action="https://jsonplaceholder.typicode.com/posts/"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">可多图上传，单张图片最大支持10M</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item style="text-align: right;">
            <el-button @click="back">取消</el-button>
            <el-button @click="save" type="primary" plain>仅保存</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="card-right">
        <div slot="header" class="clearfix">
          <span>案例创作攻略</span>
        </div>
        <div class="case-prompt">
            <div class="prompt-content">
            <div class="prompt-title">一.封面</div>
              <div class="prompt-item">1. 封面照片滤镜清淡，光线自然，构图方正。</div>
              <div class="prompt-item">2. 图片空间整洁，视角开阔，非户型图。</div>
              <div class="prompt-item">3. 展示最能够体现案例装修亮点的空间，更能睛</div>
            </div>
            <div class="prompt-content">
              <div class="prompt-title">二.标题</div>
              <div class="prompt-item">1. 标题字数在10-20字</div>
              <div class="prompt-item">
                2.
                建议可以从【空间类型】【面积】【企业名称】【设计亮点】这几点关键信息来写标题
              </div>
              <div class="prompt-item"></div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">三.方案说明</div>
                <div class="prompt-item">1. 导语字数≥50字</div>
                <div class="prompt-item">2. 禁止出现灌水、广告、图文无关等情况</div>
                <div class="prompt-item">3. 描述项目背景、客户需求、设计思路</div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">四.空间分类</div>
                <div class="prompt-item">
                  1. 办公类案例必须包含前厅、办公区，选择包含会议室，独立办公室其中1个空间
                </div>
                <div class="prompt-item">2. 建议空间数≥3个，能够全面展示整体装修情况</div>
                <div class="prompt-item"></div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">五.图片</div>
                <div class="prompt-item">
                  *图片尺寸不低于500*500px，格式可为jpg，png。图片文件大小不超过15M，横图宽高比不超过16:9，竖图高宽比不超过16:9
                </div>
                <div class="prompt-item">1. 图片分辨率高，无黑边白边，不使用局部照片</div>
                <div class="prompt-item">2. 必须包含平面方案图，且需标出空间名称，</div>
            </div>
            <div class="prompt-content">
                <div class="prompt-title">六.文案</div>
                <div class="prompt-item">1. 禁止灌水、广告、图文无关等情况</div>
                <div class="prompt-item">2. 每个主空间（前厅、办公区）都有一处描述</div>
                <div class="prompt-item">
                  3.
                  文字描述与本案例相关，建议可以描述设计思路、颜色搭配技巧、材料使用情况、空间动线处理等，具有参考价值的案例更容易被收藏哦
                </div>
            </div>
        </div>
      </el-card>

      <el-dialog title="选择团队人员" :visible.sync="dialogTableVisible">
        <el-table :data="gridData">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column property="head" label="头像" width="120"></el-table-column>
          <el-table-column property="name" label="姓名" width="120"></el-table-column>
          <el-table-column property="job" label="职称"></el-table-column>
          <el-table-column
            align="right">
            <template #header>
              <el-input
                v-model="memberName"
                size="mini"
                @change="search"
                placeholder="输入关键字搜索,回车查询"/>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="page"
          :page-size="size"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogTableVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { industryList, projectList, projectAttributes } from '@/utils/variable.js';
export default {
    name: 'case-add',
    components: {},
    data () {
      return {
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyIjoidGVzdCIsImlzQWNjb3VudE5vbkV4cGlyZWQiOm51bGwsImlzQ3JlZGVudGlhbHNOb25FeHBpcmVkIjpudWxsLCJpc0VuYWJsZWQiOm51bGwsImlzQWNjb3VudE5vbkxvY2tlZCI6bnVsbCwicGVybWlzc29ucyI6WyJhZG1pbiJdLCJ1c2VybmFtZSI6InRlc3QifQ.IsBUu2I4GBprwIzCbTEYxvmxW9T_XjLsYtu8G5Cm7beuL6gONG2S8aptRri0mMfGfYwgk_As5UWcL1TntCp1Og',
        },
        fileSize: 10,
        videoForm: {},
        videoFlag: false,
        videoUploadPercent: 0,
        industryList: industryList,
        projectList: projectList,
        projectAttributes: projectAttributes,
        form: {
          name: '',
          area: '',
          industry: '',
          projectType: '',
          attributes: '',
          desc: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入项目名称', trigger: 'blur' }
          ],
          area: [
            { required: true, message: '请输入项目地址', trigger: 'blur' }
          ],
          desc: [
            { required: true, message: '请输入项目概况', trigger: 'blur' }
          ],
          industry: [
            { required: true, message: '请选择所属行业', trigger: 'change' }
          ],
          projectType: [
            { required: true, message: '请选择项目类型', trigger: 'change' }
          ],
          attributes: [
            { required: true, message: '请选择项目属性', trigger: 'change' }
          ],
        },
        fileList: [],
        dialogTableVisible: false,
        gridData: [],
        page: 1,
        size: 20,
        total: 0,
        memberName: ''
      };
    },
    created() {
    },
    methods: {
      back() {
        this.$router.back();
      },
      save() {},
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      showDialog() {
        this.dialogTableVisible = true;
      },
      search() {
        console.log(this.memberName);
      },
      handleVideoSuccess() {
        this.videoFlag = false;
        this.videoUploadPercent = 0;
        if(res.status == 200){
            this.videoForm.videoUploadId = res.data.uploadId;
            this.videoForm.Video = res.data.uploadUrl;
        }else{
            this.$message.error('视频上传失败，请重新上传！');
        }
      },
      beforeUploadVideo(file) {
        const isLt10M = file.size / 1024 / 1024  < 10;
        if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
            this.$message.error('请上传正确的视频格式');
            return false;
        }
        if (!isLt10M) {
            this.$message.error('上传视频大小不能超过10MB哦!');
            return false;
        }
      },
      uploadVideoProcess(file) {
        this.videoFlag = true;
        this.videoUploadPercent = file.percentage.toFixed(0);
      },
      handlePictureCardPreview() {},
      handleRemove() {},
      handleBeforeUpload(file) {
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
      },
      handleUploadSuccess(res, file, fileList) {
        this.$message.success("上传成功");
        // this.fileList.push({ name: res.data.url, url: res.data.url });
        console.log(this.fileList);
      },
    }
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
}
.case-prompt {
  .prompt-content {
    margin-top: 20px;
    font-size: 14px;
    color: #303133;

    &:first-child {
      margin: 0;
    }
  }
  .prompt-title {
    font-weight: bolder;
    font-size: medium;
  }
  .prompt-item {
    margin: 5px 0;
    color: #606266;
  }
}
::v-deep .el-upload {
  position: relative;

  .mask {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(255, 255, 255, .5);

    i {
      font-size: 48px;
    }
  }

  &:hover {
    .mask {
      display: flex;
    }
  }
}
</style>