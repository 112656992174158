import request from '@/api/index'

// 查询区域列表
export function listDistrict(query) {
  return request.getRes({
    url: '/lbg/district/list',
    method: 'get',
    params: query
  })
}

// 查询区域详细
export function getDistrict(id) {
  return request.getRes({
    url: '/lbg/district/' + id,
    method: 'get'
  })
}

// 新增区域
export function addDistrict(data) {
  return request.getRes({
    url: '/lbg/district',
    method: 'post',
    data: data
  })
}

// 修改区域
export function updateDistrict(data) {
  return request.getRes({
    url: '/lbg/district',
    method: 'put',
    data: data
  })
}

// 删除区域
export function delDistrict(id) {
  return request.getRes({
    url: '/lbg/district/' + id,
    method: 'delete'
  })
}
