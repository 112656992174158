<template>
  <div>
    <div class="header">
      <div>
        <p class="title">{{ form.firmName }}</p>
        <p class="info">
          <span class="code">{{ form.clueCode}}</span>
          <span>{{ form.createTime}}</span>
        </p>
      </div>
      <a href="javascript: void(0)" class="back" @click="goBack">返回</a>
    </div>

    <div class="container">
      <div class="demand-detail-item">
        <div class="step-box">
          <div class="step-view">
            <div class="el-steps el-steps--horizontal">
              <div
                class="el-step is-horizontal is-center"
                style="flex-basis: 50%;margin-right: 0px;"
                v-for="(item,i) in (form.projectNature == 'sjxm' ? designStep : buildStep)"
                :key="i"
              >
                <template>
                  <div class="el-step__head is-process">
                    <div class="el-step__line" style="margin-right: 0px;">
                      <i class="el-step__line-inner"></i>
                    </div>
                    <div
                      v-if="isStepCheck(i)"
                      class="el-step__icon is-text step-active"
                    >
                      <i class="el-step__icon-inner is-status el-icon-check"></i>
                    </div>
                    <div v-else class="el-step__icon is-text"></div>
                  </div>
                  <div class="el-step__main">
                    <div class="el-step__title is-process">
                      {{ item.text }}
                    </div>
                    <span v-if="item.key == 'khjlsmfw' && form.khjlsmfw == 1">
                      {{form.khjlsmfwTime}}
                    </span>
                    <span v-else-if="item.key == 'sdStatus'">
                      <dict-tag :options="dict.type.lost_reason" :value="form.lostReason"/>
                    </span>
                    <span v-else-if="item.key == 'tdStatus'">
                      <dict-tag :options="dict.type.invalid_reason" :value="form.invalidReason"/>
                    </span>
                    <span v-else-if="item.key == 'zbjgStatus' && isStepCheck(i)">
                      {{form.zbjgEnterpriseName}}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <el-tabs v-model="tabsActiveName" >
          <el-tab-pane label="需求概览" name="1">
            <el-descriptions title="基础信息" labelClassName="label-class">
              <el-descriptions-item label="公司名称">{{ form.firmName }}</el-descriptions-item>
              <el-descriptions-item label="首次对接人"></el-descriptions-item>
              <el-descriptions-item label="联系方式">{{ form.firmPhoneNumber }}</el-descriptions-item>
              <el-descriptions-item label="所属行业">
                <dict-tag :options="dict.type.firm_trade" :value="form.firmTrade"/>
              </el-descriptions-item>
              <el-descriptions-item label="公司类型">
                <dict-tag :options="dict.type.firm_type" :value="form.firmType"/>
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="需求信息" labelClassName="label-class">
              <el-descriptions-item style="line-height: 32px;" label="项目面积">{{ form.measureArea }}</el-descriptions-item>
              <el-descriptions-item label="需求类型">
                <dict-tag :options="dict.type.lbg_request_type" :value="form.demandType"/>
              </el-descriptions-item>
              <el-descriptions-item label="项目类型">
                <dict-tag :options="dict.type.lbg_project_type" :value="form.projectType"/>
              </el-descriptions-item>
              <el-descriptions-item label="场地性质">
                <dict-tag :options="dict.type.lbg_site_type" :value="form.siteProperties"/>
              </el-descriptions-item>
              <el-descriptions-item label="场地状态">
                <dict-tag :options="dict.type.lbg_site_status" :value="form.siteStatus"/>
              </el-descriptions-item>
              <el-descriptions-item label="免租期">{{ form.rentFreePeriod }}</el-descriptions-item>
              <el-descriptions-item label="项目地址">{{ form.addressAll }}</el-descriptions-item>
            </el-descriptions>

            <el-descriptions title="需求计划" labelClassName="label-class">
              <el-descriptions-item label="计划装修时间">{{ form.planTime }}</el-descriptions-item>
              <el-descriptions-item label="装修预算">{{ form.budget }} （元）</el-descriptions-item>
              <el-descriptions-item label="预计勘测时间">{{ form.surveyTime }}</el-descriptions-item>
            </el-descriptions>

            <div class="el-descriptions service-descriptions">
              <div class="el-descriptions__header">
                <div class="el-descriptions__title">应标服务商</div>
              </div>
            </div>
            <div class="service-list">
              <div class="service-item" v-for="(item, index) in info" :key="index">
                <div class="shop-left">
                  <div class="shop-pic">
                    <img :src="host+item.shopImg" width="100"
                        height="100"/>
                  </div>
                  <div class="shop-brief">
                    <p class="title">
                        {{ item.storeName }}
                    </p>
                    <p class="slogan">{{ item.slogan }}</p>
                    <div class="shop-brief-bottom">
                      <dict-tag
                        style="display: inline-block;margin-right: 5px;"
                        v-for="(data, index) in item.serviceCaseTypesList"
                        :key="index"
                        :options="dict.type.lbg_enterprise_service_type"
                        :value="data.serviceType"
                      />
                    </div>
                    <div class="handle">
                      <!-- <a href="javascript: void(0)" class="btn" @click="skip(item.enterpriseId)">查看认证报告</a> -->
                      <!-- <a href="javascript: void(0)" class="btn">加入对比</a> -->
                    </div>

                  </div>
                </div>
                <div class="shop-right">
                  <div class="top">
                    <a href="javascript: void(0)" class="tel-btn" @click.stop="reserve(item)"><i></i></a>
                    <div>
                      <div>
                        <span>最新进展：</span>
                        <dict-tag
                          style="display: inline-block; color: #E94B22;"
                          :options="dict.type.business_platform_status"
                          :value="item.businessPlatformStatus"/>
                      </div>
                      <div class="shop-status" v-if="item.sjStatus==2">(已签设计)</div>
                      <div class="shop-status" v-if="item.sgStatus==2">(已签施工)</div>
                    </div>
                    
                  </div>
                  <!-- <div class="shop-status" v-if="item.sjStatus!=3">
                    <p>已签设计</p>
                    <img v-if="item.sjStatus==2" src="../../assets/step-on.png" alt="">
                    <div v-if="item.sjStatus==1 ||item.sjStatus==null"></div>
                  </div>
                  <div class="shop-status" v-if="item.sgStatus!=3">
                    <p>已签施工</p>
                    <img v-if="item.sgStatus==2" src="../../assets/step-on.png" alt="">
                    <div v-if="item.sgStatus==1||item.sgStatus==null"></div>
                  </div>
                  <div class="clear"></div> -->
                  <el-button v-if="item.sjStatus==1 && 1==2" size="mini" type="primary" @click="updateSignStatus(1,index)">设计
                  </el-button>
                  <el-button v-if="item.sgStatus==1 && 1==2" size="mini" type="primary" @click="updateSignStatus(2,index)">施工
                  </el-button>
                </div>
              </div>
            </div>
            
            <el-dialog width="900px" title="添加设计签约信息" :visible.sync="signDialog">
              <el-row>
                <el-form :model="signForm" :rules="signRules" ref="signForm">
                  <el-col :span="12">
                    <el-form-item label="合同编号" prop="signNum" :label-width="formLabelWidth">
                      <el-input v-model="signForm.signNum" placeholder="请输入合同编号" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="所属客户" prop="ownerName" :label-width="formLabelWidth">
                      <el-input v-model="signForm.ownerName" disabled placeholder="请输入所属客户" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="项目名称" prop="projectName" :label-width="formLabelWidth">
                      <el-input v-model="signForm.projectName" disabled placeholder="请输入项目名称" autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="服务商家名称" prop="enterpriseId" :label-width="formLabelWidth">
                      <el-select disabled style="width: 100%;" v-model="signForm.enterpriseId" placeholder="请选择服务商家名称">
                        <!--                <el-option v-for="(item,index) in enterprise" :key="index"-->
                        <!--                           :label="item.name" :value="item.id"></el-option>-->
                        <el-option selected :label="signForm.enterpriseName" :value="signForm.enterpriseId"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="合同类型" prop="contractType" :label-width="formLabelWidth">
                      <el-select disabled style="width: 100%;" v-model="signForm.contractType" placeholder="请选择合同类型">
                        <el-option label="设计合同" value="1"></el-option>
                        <el-option label="施工合同" value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="签约金额(元)" prop="contractAmount" :label-width="formLabelWidth">
                      <el-input type="number" v-model="signForm.contractAmount" placeholder="请输入签约金额"
                                autocomplete="off"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="buildFlag">
                    <el-form-item label="开工日期" prop="businessStartTime" :label-width="formLabelWidth">
                      <el-date-picker
                        style="width: 100%;"
                        v-model="signForm.businessStartTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="buildFlag">
                    <el-form-item label="完工日期" prop="businessFinishTime" :label-width="formLabelWidth">
                      <el-date-picker
                        style="width: 100%;"
                        v-model="signForm.businessFinishTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="签约日期" prop="signTime" :label-width="formLabelWidth">
                      <el-date-picker
                        style="width: 100%;"
                        v-model="signForm.signTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择签约日期">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="备注" :label-width="formLabelWidth">
                      <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="signForm.remarks">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="合同附件" prop="coverFileList" :label-width="formLabelWidth">
                      <el-upload
                        :action="uploadFileUrl"
                        :headers="headers"
                        :on-success="handleCoverUploadSuccess"
                        :on-remove="handleCoverUploadRemove"
                        :file-list="signForm.coverFileList"
                        :on-exceed="exceed"
                        :limit="1"
                      >
                        <!--<i class="el-icon-plus"></i>-->
                        <el-button size="small">点击上传</el-button>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-form>
              </el-row>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" v-if="signShow" @click="submitSign('signForm')">提交</el-button>
              </div>
            </el-dialog>
          </el-tab-pane>


          <el-tab-pane label="进度详情" name="2">
            <div>
              <el-table
                :data="info"
                style="width: 100%"
              >
                <el-table-column
                  prop="baseInfoList[0].enterpriseName"
                  align="center"
                  label="应标商家">
                </el-table-column>
                <el-table-column
                  prop="xqdyStatus"
                  label="需求调研"
                  align="center"
                  width="auto">
                  <template slot-scope="scope">
                    {{scope.row.xqdyStatus==1?'已参与':'未参与'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="xckcStatus"
                  align="center"
                  label="现场勘查">
                  <template slot-scope="scope">
                    {{scope.row.xckcStatus==1?'已勘测':'未勘测'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="fahbStatus"
                  align="center"
                  label="方案汇报">
                  <template slot-scope="scope">
                    {{scope.row.fahbStatus==1?'已汇报':'未汇报'}}
    <!--                <br>-->
    <!--                <el-button type="text" v-if="scope.row.fahbStatus==0">提醒报价</el-button>-->
                  </template>
                </el-table-column>
                <el-table-column
                  prop="jgbxStatus"
                  align="center"
                  label="价格比选">
                  <template slot-scope="scope">
                    {{scope.row.jgbxStatus==1?'已报价':'未报价'}}
    <!--                <br>-->
    <!--                <el-button type="text" v-if="scope.row.jgbxStatus==0">提醒报价</el-button>-->
                  </template>
                </el-table-column>
                <el-table-column
                  prop="biddingStatus"
                  align="center"
                  label="签约合同">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.biddingStatus==1"
                              :disabled="(form.sdStatus == 1 || form.tdStatus == 1) && form.sdStatus !=3"
                              size="small" type="primary" @click="updateSignStatus(1,scope.$index)">选TA签约</el-button>
                    <el-tag :style="scope.row.biddingStatus==1 ? 'margin-left: 20px': ''" :type="scope.row.biddingStatus==2?'danger':'success'">{{scope.row.biddingStatus==2?'已选择':'未选择'}}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="biddingStatus"
                  align="center"
                  label="中标通知">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.bidding_status" :value="scope.row.biddingStatus"/>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page"
                style="float: right"
                :page-size="size"
                background
                layout="total, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
       </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" width="352px" class="reserve-dialog">
        <img class="avatar" :src="`${host}${dialogData.shopImg}`" />
        <p class="name line-1">{{ dialogData.storeName }}</p>
        <div class="tel-line" v-if="dialogData.contactPhone"><i class="tel"></i>{{ dialogData.contactPhone }}</div>
        <img class="code" src="../../assets/images/qr-code.jpg" />
        <p class="text">微信咨询</p>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'

export default {
  dicts: ['lbg_request_type', 'lbg_project_type', 'lbg_site_status', 'lbg_site_type', 'business_platform_status', 'lbg_enterprise_service_type', 'firm_trade', 'firm_type', 'bidding_status','lost_reason','invalid_reason'],
  name: "demandDetail",
  data() {
    return {
      host: 'https://libangongoss.oss-cn-shanghai.aliyuncs.com/prod',
      page: 1,
      size: 10,
      total:0,
      //tabs当前选中
      tabsActiveName:'1',
      //设计商机进展  EPC商机进展
      designStep: [
        {
          key:'xqfb',
          text:'需求发布'
        },{
          key:'ddkfqr',
          text:'待客服确认'
        },{
          key:'khjlsmfw',
          text:'客户经理上门服务'
        },{
          key:'fwsybStatus',
          text:'匹配服务商'
        },{
          key:'xqdyStatus',
          text:'需求调研'
        },{
          key:'xckcStatus',
          text:'现场勘察'
        },{
          key:'fahbStatus',
          text:'方案汇报'
        },{
          key:'jgbxStatus',
          text:'价格比选'
        },{
          key:'htqyStatus',
          text:'合同签约'
        },{
          key:'zbjgStatus',
          text:'中标结果通知'
        },
        {
          key:'orderCloseStatus',
          text:'关闭/完成'
        },
        // {
        //   key:'sdStatus',
        //   text:'输单' //3
        // },{
        //   key:'tdStatus',
        //   text:'无效/退单' //2
        // },
      ],
      //施工商机进展
      buildStep: [
        {
          key:'xqfb',
          text:'需求发布'
        },{
          key:'ddkfqr',
          text:'待客服确认'
        },{
          key:'khjlsmfw',
          text:'客户经理上门服务'
        },{
          key:'fwsybStatus',
          text:'匹配服务商'
        },{
          key:'xckcStatus',
          text:'现场勘察'
        },{
          key:'fahbStatus',
          text:'招标答疑'
        },{
          key:'jgbxStatus',
          text:'价格比选'
        },{
          key:'htqyStatus',
          text:'合同签约'
        },{
          key:'zbjgStatus',
          text:'中标结果通知'
        },
        {
          key:'orderCloseStatus',
          text:'关闭/完成'
        },
        // {
        //   key:'sdStatus',
        //   text:'输单' //3
        // },{
        //   key:'tdStatus',
        //   text:'无效/退单' //2
        // },
      ],
      signShow: true,
      signDialog: false,
      buildDialog: false,
      signForm: {
        coverFileList: [],
      },
      info: [],
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken()
      },
      tableData: [],
      buildForm: {
        fileList: []
      },
      form: {},
      buildFlag: true,
      enterprise: [],
      formLabelWidth: '120px',
      signRules: {
        signNum: [{required: true, message: '请输入合同编号', trigger: 'blur'}],
        ownerName: [{required: true, message: '请输入所属客户', trigger: 'blur'}],
        name: [{required: true, message: '请输入项目名称', trigger: 'blur'}],
        contractType: [{required: true, message: '请选择合同类型', trigger: 'change'}],
        contractAmount: [{required: true, message: '请输入签约金额', trigger: 'blur'}],
        signTime: [{required: true, message: '请选择签约日期', trigger: 'change'}],
        coverFileList: [{required: true, message: '请输上传合同附件', trigger: 'change'}],
        enterpriseId: [{required: true, message: '请输确认签约商家', trigger: 'change'}],
      },
      signVos: {},
      dialogData: {},
      dialogVisible: false,
    }
  },
  mounted() {
    this.queryList()
    this.getEnterprisePosByBid()
    this.getMyDemandInfo()
    this.signForm.projectName = this.$route.query.projectName
    this.signForm.ownerName = this.$route.query.ownerName
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
    /**
     * 此步骤是否选择
     */
    isStepCheck(index) {
      if(this.form.projectNature == 'sjxm'){
        //输单状态为3 无效为2
       if((this.designStep[index].key!='orderCloseStatus'&&this.form[this.designStep[index].key] == 0)|| (this.designStep[index].key=='orderCloseStatus'&&this.form[this.designStep[index].key] == 1)){
          return false
        }else{
          return true
        }
      } else{
        //输单状态为3 无效为2
       if((this.buildStep[index].key!='orderCloseStatus'&&this.form[this.buildStep[index].key] == 0)|| (this.buildStep[index].key=='orderCloseStatus'&&this.form[this.buildStep[index].key] == 1)){
          return false
        }else{
          return true
        }
      }
    },
    /**
     * 返回
     */
    goBack() {
      this.$router.go(-1)
    },
    queryList() {
      this.$api.get('/customerApi/enterprise/list')
        .then(res => {
          if (res.code == '200') {
            this.enterprise = [...res.rows]
          }
        })
    },
    getMyDemandInfo() {
      this.$api.get('/customerApi/businessOpportunity/getMyDemandInfo?id=' + this.$route.query.id)
        .then(res => {
          if (res.code == '200') {
            this.form = res.data.businessOpportunityVo
            this.info = res.data.enterprisePosVos
            this.signVos = res.data.contractSignRecordVos;
          }
        })
    },
    getEnterprisePosByBid() {
      this.$api.get('/customerApi/businessOpportunityRef/getEnterprisePosByBid?bId=' + this.$route.query.id)
        .then(res => {
          if (res.code == '200') {

          }
        })
    },
    findServiceType(serviceCaseTypesList) {
      let caseTypes = [];
      this._.forEach(serviceCaseTypesList, x => {
        let type = _.find(this.projectTypes, space => space.value === x);
        if (type) {
          caseTypes.push(type.name)
        }
      });
      return caseTypes.join('/')
    },
    exceed() {
      this.$message.error(`只允许上传一个附件!`);
    },
    handleBeforeUpload(file) {
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
    },
    updateSignStatus(num, index) {
      let form = {
        businessOpportunityId: this.form.id,
        enterpriseId: this.info[index].eid,
        type: num
      }
      this.$api.put('/customerApi/businessOpportunity/updateSignStatus', form)
        .then(res => {
          if (res.code == '200') {
            this.$message.success(res.msg);
            this.getEnterprisePosByBid()
            this.getMyDemandInfo()
          } else {
            this.$message.error(res.msg);
          }
        })
    },
    handleCoverUploadSuccess(res, file, fileList) {
      this.signForm.coverFileList = [];
      if (res.code == 200) {
        this.signForm.coverFileList.push(res.data);
        // this.form.coverFileId = res.data.fileId;
        this.$message.success("上传成功");
      } else {
        this.signForm.coverFileList = [];
        this.$message.error(res.msg);
      }
    },
    showDialog(flag) {
      this.signShow = true
      this.signForm = {}
      this.signForm.projectName = this.$route.query.projectName
      this.signForm.ownerName = this.$route.query.ownerName
      this.signDialog = true
      this.buildFlag = flag
      for (let i in this.signVos) {
        if (flag) {
          // 找施工合同
          if (this.signVos[i].contractType == '2') {
            this.signForm = this.signVos[i]
            this.signShow = false
            return;
          }
        } else {
          // 找设计合同
          if (this.signVos[i].contractType == '1') {
            this.signForm = this.signVos[i]
            this.signShow = false
            return;
          }
        }

      }

      if (flag) {
        // 施工记录
        for (let i in this.info) {
          if (this.info[i].sgStatus == '2') {
            this.signForm.enterpriseName = this.info[i].storeName
            this.signForm.enterpriseId = this.info[i].eid
            break
          }
        }
        this.signForm.contractType = '2'
      } else {
        for (let i in this.info) {
          if (this.info[i].sjStatus == 2) {
            this.signForm.enterpriseName = this.info[i].storeName
            this.signForm.enterpriseId = this.info[i].eid
            break
          }
        }
        this.signForm.contractType = '1'
      }
    },
    reserve(store) {
      this.dialogVisible = true;
      this.dialogData = store;
    },
    handleUploadSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.fileList.push(res.data);
        this.$message.success("上传成功");
      } else {
        this.fileList = [];
        this.$message.error(res.msg);
      }
    },
    handleCoverUploadRemove(file, fileList) {
      this.signForm.coverFileList = [];
    },
    submitSign(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let data=Object.assign(this.signForm, {});
          let data = {
            ...this.signForm
          }
          delete data['coverFileList']
          data.contractFileId = this.signForm.coverFileList[0].fileId
          data.contractAmount = parseInt(this.signForm.contractAmount)
          data.businessOpportunityId = parseInt(this.$route.query.id)
          data.customerId = parseInt(this.$route.query.customerId)
          data.customerFirmId = parseInt(this.$route.query.customerFirmId)
          console.log(data)
          this.$api.post('/customerApi/contractSignRecord', data)
            .then(res => {
              if (res.code == '200') {
                this.$message.success(res.msg);
                this.signDialog = false
                this.getMyDemandInfo()
              }
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding: 9px 10px 0 25px;
  width: 100%;
  height: 86px;
  background: linear-gradient(90deg, #FF6646 0%, #FF9139 100%);

  .title {
    margin: 6px 0 10px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
  }
  .info {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
    .date {
      margin-right: 24px;
    }
  }
  .back {
    display: block;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #EFEFEF;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
  }
}
.container {
  height: auto !important;
}
.section {
  padding: 0 10px;
}
.step-box{
  padding: 10px 0;
  margin: 10px 0 70px;
  width: 100%;
  min-height: 67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  background: rgba(216, 216, 216, .1);
}
.step-view {
  flex: 1;

  .el-step__icon {
    width: 10px;
    height: 10px;
    background: #CBCFE4;
    border: 0;
  }
  .el-step__line {
    height: 1px;
  }
  .step-active {
    width: 16px;
    height: 16px;
    background: #E84B22;
    border-color: #fff;
    color: #fff;

    i {
      font-size: 12px;
    }
  }
  .el-step__line-inner {
    transition-delay: 0ms;
    border-width: 0px;
    width: 0%;
  }
  .el-step__title {
    font-size: 12px;
    color: #575C6F;
    // display: flex;
    // align-items: center;
  }
  .el-step__main {
    span {
      font-size: 12px;
    }
  }
}
::v-deep .el-descriptions {
  margin-top: 34px;
  padding-bottom: 18px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  border-bottom: 1px solid #eee;

  &:last-child {
    border: 0;
  }
  
  .label-class {
    display: block;
    width: 110px;
    text-align: right;
    color: #575C6F;
  }
  .el-select, .el-date-editor {
    width: 100%;
  }
  .el-cascader {
    min-width: 270px;
  }
}
.goBack {
  color: rgb(54, 93, 255);

  &:hover {
    cursor: pointer;
  }
}

.binding {
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #4b4d59;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9eef0;
  margin-bottom: 20px;
}

.demand-detail-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.demand-detail-item {
  margin-bottom: 10px;
}

.step-line {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;

  .step-line-inner {
    position: absolute;
    left: 50%;
    right: -50%;
    top: 10px;
    height: 2px;
    border-color: inherit;
    background-color: #c0c4cc;
  }

  .step-line-item {
    flex: 1;

    .step-line-top {
      text-align: center;
      position: relative;
      margin-bottom: 20px;
    }

    .step-line-title {
      text-align: center;
      font-size: 13px;
      color: #333;

      span {
        color: #409eff;
        text-align: center;
        font-size: 13px;
        display: block;
      }
    }

    .step-line-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      background-color: #cacfe4;
      z-index: 10;
      position: relative;
    }
  }

}

.service-descriptions {
  border: 0;
  padding-bottom: 0;
}
.service-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .service-item {
    width: calc(50% - 10px);
    overflow: hidden;
    // min-width: 400px;
    height: 150px;
    background: rgba(216, 216, 216, .1);
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .shop-left {
      display: flex;
      /*margin-right: 80px;*/
      // width: 69%;

      .shop-pic {
        width: 72px;
        height: 72px;
        border-radius: 3px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .shop-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        display: flex;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
      .tel-btn {
        margin-right: 20px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: #FFEBE5;
        border-radius: 50%;
        text-align: center;
        i {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url('~@/assets/images/tel.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .shop-status {
        // height: 20px;
        // position: relative;
        // display: inline-block;
        // padding-right: 20px;
        // margin-right: 5px;
        // margin-bottom: 5px;
        color: #333333;
        text-align: right;

        p {
          font-size: 12px;
          line-height: 20px;
        }

        img {
          position: absolute;
          right: 0;
          top: 2px;
          width: 14px;
        }

        div {
          width: 14px;
          height: 14px;
          background: #CCCCCC;
          border-radius: 50%;
          top: 2px;
          position: absolute;
          right: 0;
        }
      }
    }

    .shop-brief {
      padding-left: 16px;
      flex: 1;

      .title {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      .slogan {
        margin-top: 8px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #575C6F;
        line-height: 20px;
      }
      .handle {
        margin-top: 10px;
        display: flex;
        align-items: center;
        // justify-content: space-between;

        .btn {
          margin-right: 16px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #07A2FF;
          line-height: 20px;
        }
      }
    }
    .shop-brief-bottom {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      min-height: 32px;
    }
  }
}

.demand-step {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:before {
    content: "";
    height: 1px;
    width: 100%;
    border-top: 1px solid #cbcfe4;
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
  }

  & > div:first-child {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      left: 0;
      top: 0;
      background-color: #ffffff;
      z-index: 3;
    }
  }

  & > div:last-child {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      width: 50%;
      height: 10px;
      right: 0;
      top: 0;
      background-color: #ffffff;
      z-index: 3;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 80px;

    & > div:nth-child(1) {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #cbcfe4;
      z-index: 4;
    }

    img {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      z-index: 4;
    }

    & > span {
      padding-top: 10px;
      font-size: 14px;
    }
  }
}
/deep/.el-tabs__nav{
  width: 100%;
}
/deep/#tab-xqgl,/deep/#tab-jdxq{
  width: 50%;
  text-align: center;
}
/deep/ .reserve-dialog {
  .el-icon-close {
    font-size: 24px;
  }
  .el-dialog__body {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      border: 1px solid #eee;
    }
    .name {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
    }
    .tel-line {
      margin: 15px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 61px;
      background: #F9F9F9;
      border-radius: 3px;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E94B22;
    }
    .tel {
      margin-right: 15px;
      width: 24px;
      height: 24px;
      background: url('~@/assets/images/tel.png') no-repeat;
      background-size: 100% 100%;
    }
    .code {
      margin: 25px 0 15px;
      width: 148px;
      height: 148px;
    }
    .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>
