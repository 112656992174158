<template>
    <div class="detail-container">
        <el-card class="card-left">
            <div slot="header" class="clearfix">
                <span>品宣管理</span>
            </div>
            <el-form :inline="true" :model="form" class="form-inline" label-position="right" ref="ruleForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="banner图片">
                            <el-upload
                                class="upload-demo"
                                drag
                                action="https://jsonplaceholder.typicode.com/posts/"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">上传</div>
                                <div slot="tip" class="el-upload__tip">焦点图尺寸：1000×280像素,仅支持jpg,PNG.jpeg图片文件,文件小于等于10M</div>
                            </el-upload>
                            <div class="upload-info">
                                <span class="time">2022-02-16 17:20:56</span>
                                <el-tag type="warning">审核中</el-tag>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传视频封面">
                            <el-upload
                                class="upload-demo"
                                drag
                                action="https://jsonplaceholder.typicode.com/posts/"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">上传</div>
                                <div slot="tip" class="el-upload__tip">
                                    视频封面尺寸：280×160像素,或者是 560×320像素，
                                    仅支持jpg,PNG.jpeg图片文件,文件小于等于2M
                                </div>
                            </el-upload>
                            <!-- <div class="upload-info">
                                <span class="time">2022-02-16 17:20:56</span>
                                <el-tag type="warning">审核中</el-tag>
                            </div> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="上传视频">
                            <el-upload
                                class="upload-demo"
                                drag
                                action="https://jsonplaceholder.typicode.com/posts/"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">上传</div>
                                <div slot="tip" class="el-upload__tip">
                                    视频尺寸：16：9，
                                    仅支持MP4,AVI格式视频文件,文件小于等于150M
                                </div>
                            </el-upload>
                            <div class="upload-info">
                                <span class="time">2022-02-16 17:20:56</span>
                                <el-tag type="warning">审核中</el-tag>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'product-list',
    components: {},
    data () {
      return {
          form: {}
      };
    },
    created() {},
    methods: {}
}

</script>
<style lang='less' scoped>
::v-deep .el-card {
  display: flex;
  flex-direction: column;

  .el-card__body {
    flex: 1;
    overflow-y: auto;
  }
}
::v-deep .form-inline {
  .el-form-item {
    display: block !important;
    margin-bottom: 10px !important;
    .el-form-item__label {
      font-weight: bold;
    }
    .el-form-item__content {
      display: block !important;
    }
    .el-select {
      width: 100%;
    }
    .el-textarea__inner {
      height: 100px;
    }
  }
}
.upload-info {
    margin-top: 20px;
    .time {
        margin-right: 20px;
        color: #909399;
    }
}
</style>