import router from './router'
import store from './store'
import Api2 from './api/index.js';
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, setToken,setUserName,setUserAvatar } from '@/utils/auth'

NProgress.configure({ showSpinner: false })
function getParamQueryString (url, name) {
  let subStr = name + '='
  if (url.indexOf(subStr) !== -1) {
    let arr = url.split(subStr)
    let sub1 = arr[1]
    if (sub1.indexOf('&') !== -1) {
      let arr2 = sub1.split('&')
      let sub2 = arr2[0]
      return sub2
    } else {
      return sub1
    }
  }
  return ''
}
const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

router.beforeEach((to, from, next) => {
  NProgress.start()

  let token = getParamQueryString(window.location.href, 'token');
  console.log("111111");
  console.log(token);
 
  if(token) {
    setToken(token);
    Api2.get('/customerApi/customer/myInfo')
      .then(res => {
          if(res.code == '200') {
            console.log(res);
            setUserName(res.data.name);
            setUserAvatar(res.data.avatar)
            next()
          }
        })
    return;
  }
  console.log('2222');

  if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
      // if (store.getters.roles.length === 0) {
      //   // 判断当前用户是否已拉取完user_info信息
      //   store.dispatch('GetInfo').then(() => {
      //     store.dispatch('GenerateRoutes').then(accessRoutes => {
      //       // 根据roles权限生成可访问的路由表
      //       router.addRoutes(accessRoutes) // 动态添加可访问路由表
      //       next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      //     })
      //   }).catch(err => {
      //       store.dispatch('LogOut').then(() => {
      //         Message.error(err)
      //         next({ path: '/' })
      //       })
      //     })
      // } else {
      //   next()
      // }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {

      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      
      NProgress.done()
      // let token=getParamQueryString(window.location.href, 'token')
      // if (token==''||token==undefined||token==null){
      //   next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      //   NProgress.done()
      // }else{
      //   setToken(token);
      //   next()
      // }

    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
